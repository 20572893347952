/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { BookingModel } from './BookingModel'
import { BookingModelSelector } from './BookingModel.base'

/**
 * ConfirmBookingPayloadBase
 * auto generated base class for the model ConfirmBookingPayloadModel.
 *
 * Autogenerated return type of ConfirmBooking
 */
export const ConfirmBookingPayloadModelBase = ModelBase.named(
  'ConfirmBookingPayload'
)
  .props({
    __typename: types.optional(
      types.literal('ConfirmBookingPayload'),
      'ConfirmBookingPayload'
    ),
    booking: types.union(
      types.undefined,
      types.null,
      MSTGQLRef(types.late(() => BookingModel))
    ),
    /** A unique identifier for the client performing the mutation. */
    clientMutationId: types.union(types.undefined, types.null, types.string),
    errors: types.union(types.undefined, types.array(types.string)),
  })
  .views((self) => ({
    get store() {
      return self.__getStore()
    },
  }))

export class ConfirmBookingPayloadModelSelector extends QueryBuilder {
  get clientMutationId() {
    return this.__attr(`clientMutationId`)
  }
  get errors() {
    return this.__attr(`errors`)
  }
  booking(builder) {
    return this.__child(`booking`, BookingModelSelector, builder)
  }
}
export function selectFromConfirmBookingPayload() {
  return new ConfirmBookingPayloadModelSelector()
}

export const confirmBookingPayloadModelPrimitives = selectFromConfirmBookingPayload()
  .clientMutationId.errors
