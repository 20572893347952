import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'
import { css } from 'styled-components'
import { getCCType } from 'utils/currency'

const Payment = ({ booking, onChange: handleCcTypeChange }) => {
  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    meta,
  } = usePaymentInputs()
  const { control, register, setValue } = useFormContext()

  const handleCardNumberChange = (onChange) => (e) => {
    const val = e.target.value
    booking.setCcEnc(val)
    onChange(val)
    setValue('ccType', getCCType(val))
  }

  const handleExpiryDateChange = (onChange) => (e) => {
    const val = e.target.value?.replace(/\s+/g, '')
    onChange(val)
    booking.setCcExpiry(val)
  }

  return (
    <PaymentInputsWrapper
      {...wrapperProps}
      styles={{
        fieldWrapper: {
          base: css`
            font-size: 18px;
          `,
        },
        inputWrapper: {
          base: css`
            height: auto;
            min-height: 50px;
            padding: 13px 16px;
            color: #000a12;
            border-radius: 5px;
            border: 1px solid #b8c1cb;
            box-shadow: none;
          `,
          focused: css`
            border-width: 2px;
            border-color: #0251d6;
            box-shadow: none;
          `,
          errored: css`
            border-width: 2px;
            border-color: #ff4141;
            box-shadow: none;
          `,
        },
        input: {
          base: css`
            border: 0;
          `,
        },
        errorText: {
          base: css`
            font-size: 16px;
            color: #ff4141;
          `,
        },
      }}
    >
      <svg {...getCardImageProps({ images })} />
      <input
        name="ccType"
        type="text"
        style={{ display: 'none' }}
        ref={register({ required: true })}
      />
      <Controller
        control={control}
        name="ccNum"
        render={({ onChange, onBlur, value }) => (
          <input
            {...getCardNumberProps({
              onBlur: onBlur,
              onChange: handleCardNumberChange(onChange),
            })}
          />
        )}
        rules={{
          required: true,
          validate: (value) => meta.erroredInputs.cardNumber,
        }}
        defaultValue={''}
      />
      <Controller
        control={control}
        name="ccExpiry"
        render={({ onChange, onBlur, value }) => (
          <input
            {...getExpiryDateProps({
              onBlur: onBlur,
              onChange: handleExpiryDateChange(onChange),
            })}
            defaultValue={booking.ccExpiry}
          />
        )}
        rules={{
          required: true,
          validate: (value) => meta.erroredInputs.expiryDate,
        }}
        defaultValue={''}
      />
      <Controller
        control={control}
        name="ccCvc"
        render={({ onChange, onBlur, value }) => (
          <input {...getCVCProps({ onBlur: onBlur, onChange: onChange })} />
        )}
        rules={{ required: true, validate: (value) => meta.erroredInputs.cvc }}
        defaultValue={''}
      />
    </PaymentInputsWrapper>
  )
}

export default Payment
