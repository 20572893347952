import biRoadImage from 'assets/images/BI-Road.jpg'
import biWoodsImage from 'assets/images/BI-Road.jpg'
import lkImage from 'assets/images/LK.jpg'
import pmImage from 'assets/images/PM.jpg'
import ptImage from 'assets/images/PT.jpg'
import ttImage from 'assets/images/TT.jpg'

import slptImage from 'assets/images/SL Pull Thru.jpg'
import sllsImage from 'assets/images/SL Lake Site.jpg'
import slbiImage from 'assets/images/SL Back In.jpg'
import sl2brImage from 'assets/images/SL 2BR Park Model.jpg'
import sl2brdlxImage from 'assets/images/SL 2BR Park Model Dlx.jpg'
import sl1brImage from 'assets/images/SL 1BR Park Model.jpg'

export const amenities = [
  'Coffee & Popcorn Bar',
  'Exercise Equipment',
  'Fog for Mosquitoes Regularly',
  'Free Air Station',
  'Free Cable TV',
  'Free Computer Room with Highspeed Internet',
  'Free Wifi',
  'Full Hookup Concrete Pads',
  'Full-featured Dog Wash Station',
  'Fully Furnished',
  'Laundry Facilities',
  'Outdoor Fire Pits',
  'Outdoor Kitchen Facilities',
  'Outdoor Storage for Rent',
  'Professional & Courteous Resident Staff',
  'Propane on Site',
  'Secure & Gated Property',
  'Showers and Restrooms',
  'Stocked Fishing Lake (Catch and Release)',
  'Swimming Pool & Jacuzzi',
]

export const siteMap = {
  // Test Park
  2195: {
    images: [pmImage],
    price: '$25',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  2198: {
    images: [ptImage],
    price: '$50',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  2199: {
    images: [ttImage],
    price: '$75',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  4206: {
    images: [lkImage],
    price: '$100',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  6198: {
    images: [biWoodsImage],
    price: '$10',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  // Highway 6
  5280: {
    images: [biRoadImage, biWoodsImage],
    price: '$54',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5287: {
    images: [biRoadImage, biWoodsImage],
    price: '$54',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5291: {
    images: [biRoadImage, biWoodsImage],
    price: '$54',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5292: {
    images: [biRoadImage, biWoodsImage],
    price: '$54',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5293: {
    images: [biRoadImage, biWoodsImage],
    price: '$54',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5281: {
    images: [lkImage],
    price: '$59',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5285: {
    images: [lkImage],
    price: '$59',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5286: {
    images: [lkImage],
    price: '$59',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5288: {
    images: [lkImage],
    price: '$59',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5289: {
    images: [lkImage],
    price: '$59',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5290: {
    images: [lkImage],
    price: '$59',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5300: {
    images: [lkImage],
    price: '$59',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5283: {
    images: [ptImage],
    price: '$64',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  // SouthLake
  5382: {
    images: [slbiImage],
    price: '$55',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5383: {
    images: [slbiImage],
    price: '$55',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5384: {
    images: [slbiImage],
    price: '$55',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5385: {
    images: [slbiImage],
    price: '$55',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5386: {
    images: [slbiImage],
    price: '$55',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5387: {
    images: [slbiImage],
    price: '$55',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5388: {
    images: [slbiImage],
    price: '$55',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5389: {
    images: [sllsImage],
    price: '$62',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5390: {
    images: [sllsImage],
    price: '$62',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5391: {
    images: [sllsImage],
    price: '$62',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5392: {
    images: [sllsImage],
    price: '$62',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5393: {
    images: [slptImage],
    price: '$64',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5394: {
    images: [slptImage],
    price: '$64',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5395: {
    images: [sl1brImage],
    price: '$131',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5396: {
    images: [sl2brImage],
    price: '$146',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5397: {
    images: [sl2brdlxImage],
    price: '$162',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
  5405: {
    images: [sllsImage],
    price: '$62',
    priceLabel: 'From',
    priceHelp: '/ night',
  },
}

export const siteForAvailability = (availability) => {
  return siteMap[availability.internalUid]
}
