import React, { Fragment } from 'react'

import NextStepButton from './NextStepButton'
import ParkTerms from 'components/Park/ParkTerms'
const BookNowButton = ({
  booking,
  handleSubmit,
  handleLoading,
  handleData,
  label = 'Book Now >>',
  ...rest
}) => {
  const store = booking.store
  return (
    <Fragment>
      <NextStepButton
        label={label}
        handleSubmit={handleSubmit}
        disabled={!store.view.step('confirmation').isAvailable}
        getQuery={store.confirmBooking}
        onLoading={handleLoading}
        onData={handleData}
        onError={store.view.handleError}
        backgroundColor="tertiary"
        fontWeight="bolder"
        size="lg"
        {...rest}
      />
      <ParkTerms park={booking.park} />
    </Fragment>
  )
}

export default BookNowButton
