/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { BookingOptionModel } from './BookingOptionModel'
import { BookingOptionModelSelector } from './BookingOptionModel.base'
import { SiteTypeModel } from './SiteTypeModel'
import { SiteTypeModelSelector } from './SiteTypeModel.base'

/**
 * AvailabilityBase
 * auto generated base class for the model AvailabilityModel.
 */
export const AvailabilityModelBase = ModelBase.named('Availability')
  .props({
    __typename: types.optional(types.literal('Availability'), 'Availability'),
    available: types.union(types.undefined, types.boolean),
    bookingId: types.union(types.undefined, types.null, types.frozen()),
    bookingOptions: types.union(
      types.undefined,
      types.null,
      types.array(MSTGQLRef(types.late(() => BookingOptionModel)))
    ),
    createdAt: types.union(types.undefined, types.frozen()),
    description: types.union(types.undefined, types.null, types.string),
    descriptionLong: types.union(types.undefined, types.null, types.string),
    discountsAvailable: types.union(types.undefined, types.frozen()),
    id: types.identifier,
    maxPeople: types.union(types.undefined, types.null, types.integer),
    maxTime: types.union(types.undefined, types.null, types.integer),
    minTime: types.union(types.undefined, types.null, types.integer),
    reasonCodes: types.union(types.undefined, types.array(types.string)),
    siteType: types.union(
      types.undefined,
      types.null,
      MSTGQLRef(types.late(() => SiteTypeModel))
    ),
    siteTypeId: types.union(types.undefined, types.null, types.frozen()),
    updatedAt: types.union(types.undefined, types.frozen()),
  })
  .views((self) => ({
    get store() {
      return self.__getStore()
    },
  }))

export class AvailabilityModelSelector extends QueryBuilder {
  get available() {
    return this.__attr(`available`)
  }
  get bookingId() {
    return this.__attr(`bookingId`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get description() {
    return this.__attr(`description`)
  }
  get descriptionLong() {
    return this.__attr(`descriptionLong`)
  }
  get discountsAvailable() {
    return this.__attr(`discountsAvailable`)
  }
  get id() {
    return this.__attr(`id`)
  }
  get maxPeople() {
    return this.__attr(`maxPeople`)
  }
  get maxTime() {
    return this.__attr(`maxTime`)
  }
  get minTime() {
    return this.__attr(`minTime`)
  }
  get reasonCodes() {
    return this.__attr(`reasonCodes`)
  }
  get siteTypeId() {
    return this.__attr(`siteTypeId`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  bookingOptions(builder) {
    return this.__child(`bookingOptions`, BookingOptionModelSelector, builder)
  }
  siteType(builder) {
    return this.__child(`siteType`, SiteTypeModelSelector, builder)
  }
}
export function selectFromAvailability() {
  return new AvailabilityModelSelector()
}

export const availabilityModelPrimitives = selectFromAvailability().available
  .bookingId.createdAt.description.descriptionLong.discountsAvailable.maxPeople
  .maxTime.minTime.reasonCodes.siteTypeId.updatedAt
