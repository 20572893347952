import React, { useMemo } from 'react'
import InputSelect from 'components/Inputs/InputSelect'
import { integerOptions } from 'utils/helpers'

const UnitLength = React.forwardRef(
  ({ unitTypeId, unitTypes, error, onChange, ...rest }, ref) => {
    const unitLengths = useMemo(() => {
      const unitType = unitTypes.find((ut) => ut.id === unitTypeId)
      return unitType
        ? integerOptions(unitType.lengthMin || 1, unitType.lengthMax || 100)
        : integerOptions(1, 100)
    }, [unitTypeId, unitTypes])

    const handleChange = ({ target: { value } }) => {
      const intVal = parseInt(value)
      onChange({ target: { name: 'unitLength', value: intVal } })
    }

    return (
      <InputSelect
        ref={ref}
        name="unitLength"
        data={unitLengths}
        label="Unit Length"
        error={error}
        defaultOptionValue={0}
        onChange={handleChange}
        {...rest}
      />
    )
  }
)

export default UnitLength
