/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'

/**
 * UnitTypeBase
 * auto generated base class for the model UnitTypeModel.
 */
export const UnitTypeModelBase = ModelBase.named('UnitType')
  .props({
    __typename: types.optional(types.literal('UnitType'), 'UnitType'),
    active: types.union(types.undefined, types.boolean),
    createdAt: types.union(types.undefined, types.frozen()),
    id: types.identifier,
    internalUid: types.union(types.undefined, types.null, types.integer),
    lengthMax: types.union(types.undefined, types.integer),
    lengthMin: types.union(types.undefined, types.integer),
    lengthRequired: types.union(types.undefined, types.boolean),
    name: types.union(types.undefined, types.null, types.string),
    parkId: types.union(types.undefined, types.null, types.frozen()),
    uid: types.union(types.undefined, types.null, types.string),
    updatedAt: types.union(types.undefined, types.frozen()),
  })
  .views((self) => ({
    get store() {
      return self.__getStore()
    },
  }))

export class UnitTypeModelSelector extends QueryBuilder {
  get active() {
    return this.__attr(`active`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get id() {
    return this.__attr(`id`)
  }
  get internalUid() {
    return this.__attr(`internalUid`)
  }
  get lengthMax() {
    return this.__attr(`lengthMax`)
  }
  get lengthMin() {
    return this.__attr(`lengthMin`)
  }
  get lengthRequired() {
    return this.__attr(`lengthRequired`)
  }
  get name() {
    return this.__attr(`name`)
  }
  get parkId() {
    return this.__attr(`parkId`)
  }
  get uid() {
    return this.__attr(`uid`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
}
export function selectFromUnitType() {
  return new UnitTypeModelSelector()
}

export const unitTypeModelPrimitives = selectFromUnitType().active.createdAt
  .internalUid.lengthMax.lengthMin.lengthRequired.name.parkId.uid.updatedAt
