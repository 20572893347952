import React from 'react'
import { observer } from 'mobx-react'
import { useMediaQuery } from 'react-responsive'
import { Box, Flex, Grid, Heading, Text, Icon } from '@chakra-ui/core'

const Step = observer(({ step }) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' })

  return (
    <>
      {isDesktop ? (
        <Box position="relative">
          <Grid gridTemplateColumns="3fr 1fr" gridGap={15} alignItems="center">
            <Flex
              flexDirection="row"
              alignItems="center"
              w="full"
              maxW={step.isLastStep ? 'initial' : 262}
            >
              <Box
                size="stepCircle"
                mr={['', '', '', 2]}
                color="white"
                backgroundColor={
                  step.isCurrentStep || step.isCompleted
                    ? 'primary'
                    : 'gray.400'
                }
                borderRadius={50}
              >
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  h="full"
                  fontSize="large"
                  fontWeight="600"
                >
                  {step.isCompleted ? (
                    <Icon name="check" />
                  ) : (
                    <>{step.index + 1}</>
                  )}
                </Flex>
              </Box>
              <Heading
                as="h2"
                fontSize="h2"
                fontWeight={
                  step.isCurrentStep || step.isCompleted ? '600' : '400'
                }
                color={
                  step.isCurrentStep || step.isCompleted ? 'black' : 'gray.400'
                }
              >
                {step.label}
              </Heading>
            </Flex>
            {!step.isLastStep && (
              <Box h="px" mr={4} backgroundColor="gray.200" />
            )}
          </Grid>
        </Box>
      ) : (
        <>
          {step.isCurrentStep && (
            <Box position="relative" w="full" textAlign="center">
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  size="stepCircle"
                  mr={2}
                  color="white"
                  backgroundColor="primary"
                  borderRadius={50}
                >
                  <Flex
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    h="full"
                    fontSize="large"
                    fontWeight="600"
                  >
                    {step.completed ? (
                      <Icon name="check" />
                    ) : (
                      <>{step.index + 1}</>
                    )}
                  </Flex>
                </Box>
                <Box pt={4}>
                  <Heading as="h2" fontSize="h2" fontWeight="500">
                    {step.label}
                  </Heading>
                  <Text fontWeight="300">
                    Step {step.index + 1} of {step.numberOfSteps}
                  </Text>
                </Box>
              </Flex>
            </Box>
          )}
        </>
      )}
    </>
  )
})

export default Step
