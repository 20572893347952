import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import { Box, Flex } from '@chakra-ui/core'
import ParkDetails from 'components/Park/ParkDetails'
import BookingSummaryList from './BookingSummaryList'
import SecondaryButton from 'components/Buttons/SecondaryButton'

const EditSiteButton = observer(({ booking }) => {
  const handleEdit = () => booking.store.view.goToStep('tripDetails')

  return (
    <Fragment>
      {!booking.store.view.isStep('tripDetails') &&
        !booking.store.view.isStep('confirmation') && (
          <Box>
            <Flex flexDirection="row" justifyContent="center">
              <SecondaryButton
                label="Edit Site"
                onClick={handleEdit}
                w="full"
                maxW={['', '', '', 222]}
                mt={6}
                color={['white', 'white', 'white', 'primary']}
                borderColor={['white', 'white', 'white', 'primary']}
              />
            </Flex>
          </Box>
        )}
    </Fragment>
  )
})

const BookingSummary = observer(({ booking }) => (
  <Box
    as="aside"
    position={['', '', '', 'sticky']}
    top={['', '', '', 0]}
    w={['100%', '100%', '100%', 'auto']}
    alignSelf="flex-start"
    padding="aside"
    backgroundColor={['', '', '', 'gray.100']}
  >
    <ParkDetails park={booking.park} />
    <BookingSummaryList booking={booking} />
    <EditSiteButton booking={booking} />
  </Box>
))

export default BookingSummary
