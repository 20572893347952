import React from 'react'
import InputWrapper from './InputWrapper'
import { Input } from '@chakra-ui/core'

const InputText = React.forwardRef((props, ref) => {
  const {
    id,
    name,
    label,
    error,
    onChange,
    isRequired,
    type = 'text',
    ...rest
  } = props

  return (
    <InputWrapper
      id={id}
      name={name}
      label={label}
      error={error}
      isRequired={isRequired}
    >
      <Input
        ref={ref}
        id={name}
        name={name}
        onChange={onChange}
        fontSize="base"
        height="input"
        borderColor="gray.300"
        borderRadius={6}
        focusBorderColor="primary"
        errorBorderColor="error"
        aria-label={label}
        isRequired={isRequired}
        isInvalid={error ? true : false}
        type={type}
        {...rest}
      />
    </InputWrapper>
  )
})

export default InputText
