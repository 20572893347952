import React from 'react'
import { Flex, Box, Grid, Heading, Icon } from '@chakra-ui/core'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './validationSchema'

import BookingCostList from 'components/Booking/BookingCostList'
import FirstName from './Inputs/FirstName'
import LastName from './Inputs/LastName'
import Address from './Inputs/Address'
import AddressSecondary from './Inputs/AddressSecondary'
import City from './Inputs/City'
import State from './Inputs/State'
import Zipcode from './Inputs/Zipcode'
import Email from './Inputs/Email'
import ConfirmEmail from './Inputs/ConfirmEmail'
import Country from './Inputs/Country'
import Phone from './Inputs/Phone'
import Comments from './Inputs/Comments'
import Payment from './Inputs/Payment'

import ButtonsWrapper from 'components/Buttons/ButtonsWrapper'
import BackButton from 'components/Buttons/BackButton'
import NextStepButton from 'components/Buttons/NextStepButton'

const Checkout = ({ store, booking, errors: bookingErrors }) => {
  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: booking.checkout,
  })
  const { register, handleSubmit, errors, formState } = methods

  const onChange = ({ target: { name, value } }) => {
    booking.setValue(name, value)
    booking.store.view.step('checkout').setFormState(formState)
  }

  const handleLoading = () => {
    store.view.dismissAll()
    store.view.notify('Holding your reservation...')
  }

  const handleData = (data) => {
    store.view.dismiss('loading')
    if (data.saveCheckout.errors.length > 0) {
      store.view.notifyError([...new Set(data.saveCheckout.errors)].join(', '))
    } else if (bookingErrors.length > 0) {
      store.view.notifyError([...new Set(bookingErrors)].join(', '))
    } else {
      store.view.dismissAll()
      store.view.goToStep('confirmation')
    }
  }

  return (
    <FormProvider {...methods}>
      <form>
        <Box as="section" id="checkout">
          <BookingCostList booking={booking} />
          <Heading
            as="h2"
            mt={10}
            mb={4}
            fontSize="h2"
            fontWeight="600"
            color="primary"
          >
            Your Information
          </Heading>
          <FirstName
            ref={register}
            onChange={onChange}
            isRequired={true}
            error={errors.firstName?.message}
          />
          <LastName
            ref={register}
            onChange={onChange}
            isRequired={true}
            error={errors.lastName?.message}
          />
          <Address
            ref={register}
            onChange={onChange}
            isRequired={true}
            error={errors.address?.message}
          />
          <AddressSecondary
            ref={register}
            onChange={onChange}
            error={errors.address2?.message}
          />
          <City
            ref={register}
            onChange={onChange}
            isRequired={true}
            error={errors.city?.message}
          />
          <Country
            ref={register}
            onChange={onChange}
            isRequired={true}
            error={errors.country?.message}
          />
          <Grid gridTemplateColumns="repeat(2,1fr)" gridGap={6}>
            <State
              ref={register}
              onChange={onChange}
              isRequired={true}
              error={errors.state?.message}
              country={booking.country}
            />
            <Zipcode
              ref={register}
              onChange={onChange}
              isRequired={true}
              error={errors.postalCode?.message}
            />
          </Grid>
          <Email
            ref={register}
            onChange={onChange}
            isRequired={true}
            error={errors.email?.message}
          />
          <ConfirmEmail
            ref={register}
            onChange={onChange}
            isRequired={true}
            error={errors.confirmEmail?.message}
          />
          <Phone
            ref={register}
            onChange={onChange}
            isRequired={true}
            error={errors.phone?.message}
          />
          <Comments
            ref={register({ required: true })}
            onChange={onChange}
            error={errors.notes?.message}
          />
          <Heading
            as="h2"
            mt={10}
            mb={2}
            fontSize="h2"
            fontWeight="600"
            color="primary"
          >
            Payment Information
            <Icon name="lock" size="icon" ml={2} color="primary" />
          </Heading>
          <Flex flexDirection="row" alignItems="center" mb={4}>
            <Icon name="visa" size="iconSmall" />
            <Icon name="mastercard" size="iconSmall" ml={4} />
            <Icon name="discover" size="iconSmall" ml={4} />
            <Icon name="amex" size="iconSmall" ml={4} />
          </Flex>
          <Payment booking={booking} onChange={onChange} />
          <ButtonsWrapper flexWrap="wrap" justifyContent="flex-end">
            <BackButton label="Go Back" goToStep="pickSite" />
            <NextStepButton
              label="Checkout"
              handleSubmit={handleSubmit}
              disabled={
                !store.view.step('checkout').isAvailable || !formState.isValid
              }
              getQuery={store.saveCheckout}
              onLoading={handleLoading}
              onData={handleData}
              onError={store.view.handleError}
            />
          </ButtonsWrapper>
        </Box>
      </form>
    </FormProvider>
  )
}

export default Checkout
