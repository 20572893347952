import React from 'react'
import InputWrapper from './InputWrapper'
import { Select } from '@chakra-ui/core'

const InputSelect = React.forwardRef(
  (
    {
      id,
      name,
      label,
      data,
      error,
      onChange,
      valueProperty = 'value',
      labelProperty = 'label',
      defaultOptionValue = '',
      isRequired,
      ...rest
    },
    ref
  ) => (
    <InputWrapper
      id={id}
      name={name}
      label={label}
      error={error}
      isRequired={isRequired}
    >
      <Select
        placeholder="Select"
        ref={ref}
        id={name}
        name={name}
        fontSize="base"
        height="input"
        borderColor="gray.300"
        borderRadius={6}
        focusBorderColor="primary"
        errorBorderColor="error"
        iconSize={6}
        iconColor="black"
        isInvalid={error ? true : false}
        onChange={onChange}
        {...rest}
      >
        {data &&
          data.map((o, index) => (
            <option key={index} value={o[valueProperty]}>
              {o[labelProperty]}
            </option>
          ))}
      </Select>
    </InputWrapper>
  )
)

export default InputSelect
