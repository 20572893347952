import React from 'react'
import { observer } from 'mobx-react'
import { useQuery } from 'models/reactUtils'
import SecondaryButton from 'components/Buttons/SecondaryButton'

const BackButton = observer(({ label = 'Go Back', goToStep, ...rest }) => {
  const { store } = useQuery()

  const handleClick = () => store.view.goToStep(goToStep)

  return (
    <SecondaryButton
      label={label}
      onClick={handleClick}
      w={['full', 'full', 'full', 'auto']}
      {...rest}
    />
  )
})

export default BackButton
