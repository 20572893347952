import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import { Text } from '@chakra-ui/core'

const ParkAddress = observer(({ park }) => (
  <Fragment>
    {park.name}
    <br />
    {park.address} {park.address2}
    <br />
    {park.city}, {park.state} {park.postalCode}
    <br />
    {park.phone}
    <br />
    {park.email}
  </Fragment>
))

const BookingReceipt = observer(({ booking }) => (
  <Fragment>
    <Text mb={4} fontSize="largeBody" fontWeight="600">
      Your confirmation code is #{booking.bookingNumber}.
    </Text>
    <Text mb={4} fontSize="largeBody" fontWeight="600">
      Your {booking.holdDeposit} Deposit was billed to {booking.ccType} ending
      in {booking.ccNum4}.
    </Text>
    <Text mb={4} fontSize="largeBody">
      Your remaining balance will be due upon check-in.*
    </Text>
    <Text mb={4} fontSize="largeBody">
      We look forward to seeing you soon.
    </Text>
    <Text mb={4} fontSize="largeBody">
      <ParkAddress park={booking.park} />
    </Text>
    <Text fontSize="legal" fontWeight="300">
      *Total amount may not include applicable fees. Your balance will be due at
      check-in.
    </Text>
  </Fragment>
))

export default BookingReceipt
