import * as yup from 'yup'
import moment from 'moment'
import { MomentDateSchemaType } from 'utils/date'

export const validationSchema = yup.object().shape({
  arrivalDate: new MomentDateSchemaType()
    .default(function () {
      return moment().startOf('day')
    })
    .required('Arrival date is required.')
    .typeError('Arrival date is not valid.'),
  departureDate: new MomentDateSchemaType()
    .default(function () {
      return moment().endOf('day')
    })
    .required('Departure date is required.')
    .typeError('Departure date is not valid.')
    .min(yup.ref('arrivalDate'), 'Departure date must be after arrival.'),
  numberOfNights: yup
    .number()
    .moreThan(0, 'Number of nights must one or more.'),
  unitTypeId: yup.string().required('A Unit Type is required.'),
  unitLength: yup
    .number('Must be a number')
    .typeError('A Unit Length between 1-100 ft is required.')
    .moreThan(0, 'A Unit Length between 1-100 ft is required.'),
})
