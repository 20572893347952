import React from 'react'
import { Button } from '@chakra-ui/core'

const PrimaryButton = ({ label, backgroundColor = 'primary', ...rest }) => {
  return (
    <>
      <Button
        height="input"
        px={6}
        py={3}
        fontSize="button"
        color="white"
        letterSpacing="button"
        textTransform="uppercase"
        backgroundColor={backgroundColor}
        borderWidth={1}
        borderStyle="solid"
        borderColor="transparent"
        borderRadius={25}
        {...rest}
      >
        {label}
      </Button>
    </>
  )
}

export default PrimaryButton
