/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { AvailabilityModel } from './AvailabilityModel'
import { AvailabilityModelSelector } from './AvailabilityModel.base'
import { ParkModel } from './ParkModel'
import { ParkModelSelector } from './ParkModel.base'
import { SiteTypeModel } from './SiteTypeModel'
import { SiteTypeModelSelector } from './SiteTypeModel.base'
import { UnitTypeModel } from './UnitTypeModel'
import { UnitTypeModelSelector } from './UnitTypeModel.base'

/**
 * BookingBase
 * auto generated base class for the model BookingModel.
 */
export const BookingModelBase = ModelBase.named('Booking')
  .props({
    __typename: types.optional(types.literal('Booking'), 'Booking'),
    action: types.union(types.undefined, types.integer),
    address: types.union(types.undefined, types.null, types.string),
    address2: types.union(types.undefined, types.null, types.string),
    altPhone: types.union(types.undefined, types.null, types.string),
    arrivalDate: types.union(types.undefined, types.null, types.frozen()),
    availabilities: types.union(
      types.undefined,
      types.null,
      types.array(MSTGQLRef(types.late(() => AvailabilityModel)))
    ),
    availability: types.union(
      types.undefined,
      types.null,
      MSTGQLRef(types.late(() => AvailabilityModel))
    ),
    availabilityId: types.union(types.undefined, types.null, types.frozen()),
    availableSites: types.union(types.undefined, types.array(types.string)),
    bookedAt: types.union(types.undefined, types.null, types.frozen()),
    bookingNumber: types.union(types.undefined, types.null, types.string),
    bookingQuoteId: types.union(types.undefined, types.null, types.string),
    bookingRequestId: types.union(types.undefined, types.null, types.string),
    bookingSuccess: types.union(types.undefined, types.boolean),
    bookingUuid: types.union(types.undefined, types.null, types.frozen()),
    cancelledAt: types.union(types.undefined, types.null, types.frozen()),
    ccEnc: types.union(types.undefined, types.null, types.string),
    ccExpiry: types.union(types.undefined, types.null, types.string),
    ccNum4: types.union(types.undefined, types.null, types.string),
    ccType: types.union(types.undefined, types.null, types.string),
    city: types.union(types.undefined, types.null, types.string),
    country: types.union(types.undefined, types.string),
    createdAt: types.union(types.undefined, types.frozen()),
    departureDate: types.union(types.undefined, types.null, types.frozen()),
    depositRequiredCents: types.union(types.undefined, types.integer),
    depositRequiredCurrency: types.union(types.undefined, types.string),
    discountCents: types.union(types.undefined, types.integer),
    discountCode: types.union(types.undefined, types.null, types.string),
    discountCurrency: types.union(types.undefined, types.string),
    discountText: types.union(types.undefined, types.null, types.string),
    email: types.union(types.undefined, types.null, types.string),
    firstName: types.union(types.undefined, types.null, types.string),
    heldAt: types.union(types.undefined, types.null, types.frozen()),
    holdDepositCents: types.union(types.undefined, types.integer),
    holdDepositCurrency: types.union(types.undefined, types.string),
    holdDuration: types.union(types.undefined, types.integer),
    holdSuccess: types.union(types.undefined, types.boolean),
    holdToken: types.union(types.undefined, types.null, types.string),
    holdTotalCents: types.union(types.undefined, types.integer),
    holdTotalCurrency: types.union(types.undefined, types.string),
    id: types.identifier,
    includeUnavailable: types.union(types.undefined, types.boolean),
    lastName: types.union(types.undefined, types.null, types.string),
    loyaltyCode: types.union(types.undefined, types.null, types.string),
    loyaltyText: types.union(types.undefined, types.null, types.string),
    memberId: types.union(types.undefined, types.null, types.string),
    messages: types.union(types.undefined, types.string),
    notes: types.union(types.undefined, types.null, types.string),
    park: types.union(types.undefined, MSTGQLRef(types.late(() => ParkModel))),
    parkId: types.union(types.undefined, types.null, types.frozen()),
    phone: types.union(types.undefined, types.null, types.string),
    postalCode: types.union(types.undefined, types.null, types.string),
    publicKey: types.union(types.undefined, types.null, types.string),
    quotedAt: types.union(types.undefined, types.null, types.frozen()),
    searchedAt: types.union(types.undefined, types.null, types.frozen()),
    siteChoices: types.union(types.undefined, types.boolean),
    siteTypes: types.union(
      types.undefined,
      types.null,
      MSTGQLRef(types.late(() => SiteTypeModel))
    ),
    sites: types.union(types.undefined, types.array(types.string)),
    state: types.union(types.undefined, types.null, types.string),
    status: types.union(types.undefined, types.integer),
    subtotalCents: types.union(types.undefined, types.integer),
    subtotalCurrency: types.union(types.undefined, types.string),
    taxCents: types.union(types.undefined, types.integer),
    taxCurrency: types.union(types.undefined, types.string),
    taxes: types.union(types.undefined, types.frozen()),
    termsAccepted: types.union(types.undefined, types.boolean),
    totalCents: types.union(types.undefined, types.integer),
    totalCurrency: types.union(types.undefined, types.string),
    transactionFeeCents: types.union(types.undefined, types.integer),
    transactionFeeCurrency: types.union(types.undefined, types.string),
    unitLength: types.union(types.undefined, types.null, types.integer),
    unitType: types.union(
      types.undefined,
      types.null,
      MSTGQLRef(types.late(() => UnitTypeModel))
    ),
    unitTypeId: types.union(types.undefined, types.null, types.frozen()),
    updatedAt: types.union(types.undefined, types.frozen()),
  })
  .views((self) => ({
    get store() {
      return self.__getStore()
    },
  }))

export class BookingModelSelector extends QueryBuilder {
  get action() {
    return this.__attr(`action`)
  }
  get address() {
    return this.__attr(`address`)
  }
  get address2() {
    return this.__attr(`address2`)
  }
  get altPhone() {
    return this.__attr(`altPhone`)
  }
  get arrivalDate() {
    return this.__attr(`arrivalDate`)
  }
  get availabilityId() {
    return this.__attr(`availabilityId`)
  }
  get availableSites() {
    return this.__attr(`availableSites`)
  }
  get bookedAt() {
    return this.__attr(`bookedAt`)
  }
  get bookingNumber() {
    return this.__attr(`bookingNumber`)
  }
  get bookingQuoteId() {
    return this.__attr(`bookingQuoteId`)
  }
  get bookingRequestId() {
    return this.__attr(`bookingRequestId`)
  }
  get bookingSuccess() {
    return this.__attr(`bookingSuccess`)
  }
  get bookingUuid() {
    return this.__attr(`bookingUuid`)
  }
  get cancelledAt() {
    return this.__attr(`cancelledAt`)
  }
  get ccEnc() {
    return this.__attr(`ccEnc`)
  }
  get ccExpiry() {
    return this.__attr(`ccExpiry`)
  }
  get ccNum4() {
    return this.__attr(`ccNum4`)
  }
  get ccType() {
    return this.__attr(`ccType`)
  }
  get city() {
    return this.__attr(`city`)
  }
  get country() {
    return this.__attr(`country`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get departureDate() {
    return this.__attr(`departureDate`)
  }
  get depositRequiredCents() {
    return this.__attr(`depositRequiredCents`)
  }
  get depositRequiredCurrency() {
    return this.__attr(`depositRequiredCurrency`)
  }
  get discountCents() {
    return this.__attr(`discountCents`)
  }
  get discountCode() {
    return this.__attr(`discountCode`)
  }
  get discountCurrency() {
    return this.__attr(`discountCurrency`)
  }
  get discountText() {
    return this.__attr(`discountText`)
  }
  get email() {
    return this.__attr(`email`)
  }
  get firstName() {
    return this.__attr(`firstName`)
  }
  get heldAt() {
    return this.__attr(`heldAt`)
  }
  get holdDepositCents() {
    return this.__attr(`holdDepositCents`)
  }
  get holdDepositCurrency() {
    return this.__attr(`holdDepositCurrency`)
  }
  get holdDuration() {
    return this.__attr(`holdDuration`)
  }
  get holdSuccess() {
    return this.__attr(`holdSuccess`)
  }
  get holdToken() {
    return this.__attr(`holdToken`)
  }
  get holdTotalCents() {
    return this.__attr(`holdTotalCents`)
  }
  get holdTotalCurrency() {
    return this.__attr(`holdTotalCurrency`)
  }
  get id() {
    return this.__attr(`id`)
  }
  get includeUnavailable() {
    return this.__attr(`includeUnavailable`)
  }
  get lastName() {
    return this.__attr(`lastName`)
  }
  get loyaltyCode() {
    return this.__attr(`loyaltyCode`)
  }
  get loyaltyText() {
    return this.__attr(`loyaltyText`)
  }
  get memberId() {
    return this.__attr(`memberId`)
  }
  get messages() {
    return this.__attr(`messages`)
  }
  get notes() {
    return this.__attr(`notes`)
  }
  get parkId() {
    return this.__attr(`parkId`)
  }
  get phone() {
    return this.__attr(`phone`)
  }
  get postalCode() {
    return this.__attr(`postalCode`)
  }
  get publicKey() {
    return this.__attr(`publicKey`)
  }
  get quotedAt() {
    return this.__attr(`quotedAt`)
  }
  get searchedAt() {
    return this.__attr(`searchedAt`)
  }
  get siteChoices() {
    return this.__attr(`siteChoices`)
  }
  get sites() {
    return this.__attr(`sites`)
  }
  get state() {
    return this.__attr(`state`)
  }
  get status() {
    return this.__attr(`status`)
  }
  get subtotalCents() {
    return this.__attr(`subtotalCents`)
  }
  get subtotalCurrency() {
    return this.__attr(`subtotalCurrency`)
  }
  get taxCents() {
    return this.__attr(`taxCents`)
  }
  get taxCurrency() {
    return this.__attr(`taxCurrency`)
  }
  get taxes() {
    return this.__attr(`taxes`)
  }
  get termsAccepted() {
    return this.__attr(`termsAccepted`)
  }
  get totalCents() {
    return this.__attr(`totalCents`)
  }
  get totalCurrency() {
    return this.__attr(`totalCurrency`)
  }
  get transactionFeeCents() {
    return this.__attr(`transactionFeeCents`)
  }
  get transactionFeeCurrency() {
    return this.__attr(`transactionFeeCurrency`)
  }
  get unitLength() {
    return this.__attr(`unitLength`)
  }
  get unitTypeId() {
    return this.__attr(`unitTypeId`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  availabilities(builder) {
    return this.__child(`availabilities`, AvailabilityModelSelector, builder)
  }
  availability(builder) {
    return this.__child(`availability`, AvailabilityModelSelector, builder)
  }
  park(builder) {
    return this.__child(`park`, ParkModelSelector, builder)
  }
  siteTypes(builder) {
    return this.__child(`siteTypes`, SiteTypeModelSelector, builder)
  }
  unitType(builder) {
    return this.__child(`unitType`, UnitTypeModelSelector, builder)
  }
}
export function selectFromBooking() {
  return new BookingModelSelector()
}

export const bookingModelPrimitives = selectFromBooking().action.address
  .address2.altPhone.arrivalDate.availabilityId.availableSites.bookedAt
  .bookingNumber.bookingQuoteId.bookingRequestId.bookingSuccess.bookingUuid
  .cancelledAt.ccEnc.ccExpiry.ccNum4.ccType.city.country.createdAt.departureDate
  .depositRequiredCents.depositRequiredCurrency.discountCents.discountCode
  .discountCurrency.discountText.email.firstName.heldAt.holdDepositCents
  .holdDepositCurrency.holdDuration.holdSuccess.holdToken.holdTotalCents
  .holdTotalCurrency.includeUnavailable.lastName.loyaltyCode.loyaltyText
  .memberId.messages.notes.parkId.phone.postalCode.publicKey.quotedAt.searchedAt
  .siteChoices.sites.state.status.subtotalCents.subtotalCurrency.taxCents
  .taxCurrency.taxes.termsAccepted.totalCents.totalCurrency.transactionFeeCents
  .transactionFeeCurrency.unitLength.unitTypeId.updatedAt
