import React from 'react'
import { observer } from 'mobx-react'
import { Heading, List, Divider, Text } from '@chakra-ui/core'
import BookingCostListItem from './BookingCostListItem'

const SubtotalCostListItem = observer(({ booking }) => {
  const nightsLabel = booking.numberOfNights > 1 ? 'Nights' : 'Night'
  const label = `${booking.currentAvailability?.description} X ${booking.numberOfNights} ${nightsLabel}`
  return booking.currentAvailability ? (
    <BookingCostListItem label={label} value={booking.subtotal} />
  ) : null
})

const BookingCostList = observer(({ booking }) => {
  return (
    <>
      <Heading as="h2" mb={4} fontSize="h2" fontWeight="600" color="primary">
        Your Reservation Cost
      </Heading>
      <List>
        <SubtotalCostListItem booking={booking} />
        <BookingCostListItem label="Tax" value={booking.tax} />
        <Divider my={4} />
        <BookingCostListItem
          label="Total Reservation Cost*"
          value={booking.total}
          fontWeight="600"
        />
        <BookingCostListItem
          label="Deposit Due Today"
          value={booking.depositRequired}
          fontWeight="600"
        />
      </List>
      <Text mb={3} fontSize="legal" fontWeight="300">
        * Total amount may not include applicable fees. Your balance will be due
        at check-in.
      </Text>
      <Text mb={10} fontSize="legal" fontWeight="300">
        * Good Sam discounts will be applied at check-in.
      </Text>
    </>
  )
})

export default BookingCostList
