import React from 'react'
import InputSelect from 'components/Inputs/InputSelect'
import { stateOptions } from 'utils/i18n'

const State = React.forwardRef(({ country, ...rest }, ref) => (
  <InputSelect
    ref={ref}
    name="state"
    label="State / Province"
    data={stateOptions(country)}
    {...rest}
  />
))

export default State
