import React from 'react'
import { observer } from 'mobx-react'
import { useMediaQuery } from 'react-responsive'
import Header from 'components/Header/Header'
import Stepper from 'components/Stepper/Stepper'
import { Box, Grid } from '@chakra-ui/core'
import BookingSummary from './BookingSummary'
import Footer from 'components/Footer/Footer'
import { useQuery } from 'models/reactUtils'
import GlobalError from 'components/Error/GlobalError'
import Overlay from 'components/Loading/Overlay'

const BookingStep = observer(({ children, booking }) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' })
  const { query } = useQuery((store) =>
    store.queryBooking({ id: store.bookingId })
  )

  return (
    <>
      {query.case({
        error: (error) => <GlobalError error={error} />,
        loading: () => <Overlay />,
        data: ({ booking }) => (
          <>
            <Header booking={booking} />
            <Box as="main" fontWeight="400">
              <Box w="full" maxW="default" mx="auto" px={[4, '', '', '']}>
                <Stepper />
                <Grid
                  gridTemplateColumns={['', '', '', '1fr 2fr']}
                  gridGap={['', '', '', 30]}
                >
                  {isDesktop && <BookingSummary booking={booking} />}
                  {children}
                </Grid>
              </Box>
            </Box>
            <Footer />{' '}
          </>
        ),
      })}
    </>
  )
})

export default BookingStep
