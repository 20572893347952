import React from 'react'
import styled from '@emotion/styled'
import { Box, Heading, Text } from '@chakra-ui/core'

const ErrorContainer = styled.div`
  display: grid;
  place-items: center;
  height: 100vh;
`

const GlobalError = ({ error }) => (
  <ErrorContainer>
    <Box textAlign="center">
      <Heading as="h1" mb={4} fontSize="h1">
        Whoops! Looks like something went wrong.
      </Heading>
      <Text fontSize="base">
        Our development team has been notified and looking into the error.
      </Text>
    </Box>
  </ErrorContainer>
)

export default GlobalError
