/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { AvailabilityModel } from './AvailabilityModel'
import { AvailabilityModelSelector } from './AvailabilityModel.base'

/**
 * BookingOptionBase
 * auto generated base class for the model BookingOptionModel.
 */
export const BookingOptionModelBase = ModelBase.named('BookingOption')
  .props({
    __typename: types.optional(types.literal('BookingOption'), 'BookingOption'),
    availability: types.union(
      types.undefined,
      MSTGQLRef(types.late(() => AvailabilityModel))
    ),
    availabilityId: types.union(types.undefined, types.null, types.frozen()),
    createdAt: types.union(types.undefined, types.frozen()),
    description: types.union(types.undefined, types.null, types.string),
    fieldType: types.union(types.undefined, types.integer),
    id: types.identifier,
    maxValue: types.union(types.undefined, types.null, types.integer),
    minValue: types.union(types.undefined, types.null, types.integer),
    personFlag: types.union(types.undefined, types.boolean),
    quantity: types.union(types.undefined, types.integer),
    updatedAt: types.union(types.undefined, types.frozen()),
  })
  .views((self) => ({
    get store() {
      return self.__getStore()
    },
  }))

export class BookingOptionModelSelector extends QueryBuilder {
  get availabilityId() {
    return this.__attr(`availabilityId`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get description() {
    return this.__attr(`description`)
  }
  get fieldType() {
    return this.__attr(`fieldType`)
  }
  get id() {
    return this.__attr(`id`)
  }
  get maxValue() {
    return this.__attr(`maxValue`)
  }
  get minValue() {
    return this.__attr(`minValue`)
  }
  get personFlag() {
    return this.__attr(`personFlag`)
  }
  get quantity() {
    return this.__attr(`quantity`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  availability(builder) {
    return this.__child(`availability`, AvailabilityModelSelector, builder)
  }
}
export function selectFromBookingOption() {
  return new BookingOptionModelSelector()
}

export const bookingOptionModelPrimitives = selectFromBookingOption()
  .availabilityId.createdAt.description.fieldType.maxValue.minValue.personFlag
  .quantity.updatedAt
