import React from 'react'
import { Heading } from '@chakra-ui/core'
import ParkRules from './ParkRules'

const ParkDetails = ({ park }) => {
  return (
    <>
      <Heading
        as="h4"
        mb={4}
        fontSize="h4"
        fontWeight="500"
        textTransform="uppercase"
      >
        {park.name}
      </Heading>
      <ParkRules park={park} />
    </>
  )
}

export default ParkDetails
