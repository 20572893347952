import React from 'react'
import { observer } from 'mobx-react'
import { Alert, AlertDescription, Flex, Icon } from '@chakra-ui/core'

const holdMessage = (booking) => {
  if (booking.isBooked) {
    return {
      color: 'success',
      status: 'success',
      message: `Your reservation has been booked.`,
    }
  } else if (booking.holdSuccess) {
    if (booking.isExpired) {
      return {
        color: 'error',
        status: 'error',
        message: `Your site hold has expired.`,
      }
    } else {
      return {
        color: 'error',
        status: 'error',
        message: `Your site will be held ${booking.holdDuration} minutes.  Click BOOK NOW.`,
      }
    }
  } else {
    return {
      color: 'error',
      status: 'error',
      message: 'Your site is not on hold.',
    }
  }
}

const HoldAlert = observer(({ color, status, message }) => (
  <Alert mb={6} fontSize="alert" fontWeight="600" color={color} status={status}>
    <AlertDescription>
      <Flex flexDirection="row" alignItems="center">
        <Icon name="time" mr={2} />
        {message}
      </Flex>
    </AlertDescription>
  </Alert>
))

const BookingHoldNotice = observer(({ booking }) => {
  return <HoldAlert {...holdMessage(booking)} />
})

export default BookingHoldNotice
