import { types, getParent, applySnapshot } from 'mobx-state-tree'

export const FormState = types.model({
  dirty: false,
  // dirtyFields: types.map(types.map(types.object)),
  isSubmitted: false,
  isSubmitting: false,
  submitCount: types.optional(types.integer, 0),
  // touched: types.map(types.map(types.object)),
  isValid: false,
})

export const StepModel = types
  .model({
    id: types.identifier,
    slug: types.string,
    label: types.string,
    formState: types.optional(FormState, {}),
  })
  .views((self) => ({
    get stepMap() {
      return getParent(self)
    },
    get view() {
      return getParent(self.stepMap)
    },
    get store() {
      return getParent(self.view)
    },
    get booking() {
      return self.store.booking
    },
    get index() {
      return self.view.stepIndex(self.id)
    },
    get numberOfSteps() {
      return self.stepMap.size
    },
    get isCurrentStep() {
      return self.view.isCurrentStep(self.id)
    },
    get isLastStep() {
      return self.view.stepIndex(self.id) === self.stepMap.size - 1
    },
  }))
  .actions((self) => ({
    setSlug(slug) {
      self.slug = slug
    },
    setLabel(label) {
      self.label = label
    },
    setFormState(formState) {
      const { dirtyFields, touched, ...rest } = formState
      applySnapshot(self.formState, { ...rest })
    },
  }))

export const TripDetailsStepModel = StepModel.named(
  'TripDetailsStepModel'
).views((self) => ({
  get isAvailable() {
    return !self.booking.isBooked
  },
  get isCompleted() {
    return (
      self.booking &&
      self.store.availabilities.size > 0 &&
      self.booking.reasonCodes.length === 0
    )
  },
}))

export const PickSiteStepModel = StepModel.named('PickSiteStepModel').views(
  (self) => ({
    get isAvailable() {
      return self.booking.isSearched
    },
    get isCompleted() {
      return (
        self.booking &&
        self.booking.availableSites.length > 0 &&
        self.booking.availability
      )
    },
  })
)

export const CheckoutStepModel = StepModel.named('CheckoutModel').views(
  (self) => ({
    get isAvailable() {
      return self.booking.currentAvailability
    },
    get isCompleted() {
      return self.booking && self.booking.isOnHold
    },
  })
)

export const ConfirmationStepModel = StepModel.named(
  'ConfirmationStepModel'
).views((self) => ({
  get isAvailable() {
    return (
      self.booking.isOnHold || self.booking.isBooked || self.booking.isCancelled
    )
  },
  get isCompleted() {
    return self.booking && self.booking.isBooked
  },
}))
