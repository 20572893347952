import React, { Fragment } from 'react'
import { observer } from 'mobx-react'

import { Image } from '@chakra-ui/core'
import styled from '@emotion/styled'

const CarouselContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  position: relative;
  z-index: 50;
  width: auto;
  min-width: 100%;
  margin-top: 24px;
  margin-right: ${({ margin }) => margin};
  scroll-behavior: smooth;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;

  > div:first-of-type {
    padding-left: 0;
  }
`

const CarouselImage = styled.div`
  flex: 0 0 auto;
  width: ${({ width }) => width};
  height: 100%;
  padding-left: 15px;
  scroll-snap-align: start;
`

const SiteImages = observer((props) => {
  const { site, margin } = props
  return (
    <Fragment>
      {site.images && (
        <CarouselContainer margin={margin}>
          {site.images.map((image, index) => {
            const width = site.images.length > 1 ? '90%' : '100%'

            return (
              <CarouselImage key={index} width={width}>
                <Image w="full" h="full" src={image} alt={site.label} />
              </CarouselImage>
            )
          })}
        </CarouselContainer>
      )}
    </Fragment>
  )
})

export default SiteImages
