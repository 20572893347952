import React from 'react'
import { Box } from '@chakra-ui/core'

const Footer = () => {
  return (
    <>
      <Box as="footer" py={4}></Box>
    </>
  )
}

export default Footer
