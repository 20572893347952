import React from 'react'
import { observer } from 'mobx-react'
import { Switch, Route, Redirect } from 'react-router-dom'

import BookingStep from 'components/Booking/BookingStep'
import TripDetails from 'components/TripDetails/TripDetails'
import PickSite from 'components/PickSite/PickSite'
import Checkout from 'components/Checkout/Checkout'
import Confirmation from 'components/Confirmation/Confirmation'

const Routing = observer(({ store, booking, errors }) => {
  store.setBookingId(booking.id)

  return (
    <BookingStep booking={booking}>
      <Switch>
        {store.view.step('tripDetails').isAvailable && (
          <Route path="/trip-details">
            <TripDetails store={store} booking={booking} errors={errors} />
          </Route>
        )}
        {store.view.step('pickSite').isAvailable && (
          <Route path="/pick-site">
            <PickSite store={store} booking={booking} errors={errors} />
          </Route>
        )}
        {store.view.step('checkout').isAvailable && (
          <Route path="/checkout">
            <Checkout store={store} booking={booking} errors={errors} />
          </Route>
        )}
        {store.view.step('confirmation').isAvailable && (
          <Route path="/confirmation">
            <Confirmation store={store} booking={booking} errors={errors} />
          </Route>
        )}
        <Route path="*">
          <Redirect
            to={
              store.view.step('tripDetails').isAvailable
                ? '/trip-details'
                : '/confirmation'
            }
          />
        </Route>
      </Switch>
    </BookingStep>
  )
})

export default Routing
