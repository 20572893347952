import React, { useRef } from 'react'
import { observer } from 'mobx-react'
import { useMediaQuery } from 'react-responsive'
import {
  Box,
  Flex,
  Icon,
  Image,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/core'
import BookingSummary from 'components/Booking/BookingSummary'

const Header = observer(({ booking }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' })
  const drawerRef = useRef()

  return (
    <>
      <Box
        as="header"
        mb={[4, 4, 4, 10]}
        py={4}
        px={4}
        borderBottom={[1, 1, 1, 0]}
        borderStyle="solid"
        borderColor="gray.300"
      >
        <Box w="full" maxW="default" mx="auto">
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {!isDesktop && (
              <Button
                px="0"
                variant="ghost"
                onClick={booking.store.view.goBack}
                disabled={!booking.store.view.canGoBack}
              >
                <Icon name="back" size="icon" color="gray.300" />
              </Button>
            )}
            <Box w={[180, 180, 180, '3xs']} mx={['auto', 'auto', 'auto', 0]}>
              <Image src={booking.park.logoUrl} alt="Alt Description" />
            </Box>
            {!isDesktop && (
              <Button
                ref={drawerRef}
                onClick={onOpen}
                ml="auto"
                mr="0"
                px="0"
                variant="ghost"
              >
                <Icon name="info" size="icon" color="primary" />
              </Button>
            )}
          </Flex>
        </Box>
      </Box>
      {!isDesktop && (
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={drawerRef}
          size="xs"
        >
          <DrawerOverlay />
          <DrawerContent color="white" backgroundColor="gray.400">
            <DrawerCloseButton />
            <BookingSummary booking={booking} />
          </DrawerContent>
        </Drawer>
      )}
    </>
  )
})

export default Header
