/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
import { types } from 'mobx-state-tree'
import { MSTGQLStore, configureStoreMixin } from 'mst-gql'

import { AvailabilityModel } from './AvailabilityModel'
import {
  availabilityModelPrimitives,
  AvailabilityModelSelector,
} from './AvailabilityModel.base'
import { BookingModel } from './BookingModel'
import {
  bookingModelPrimitives,
  BookingModelSelector,
} from './BookingModel.base'
import { BookingOptionModel } from './BookingOptionModel'
import {
  bookingOptionModelPrimitives,
  BookingOptionModelSelector,
} from './BookingOptionModel.base'
import { ConfirmBookingPayloadModel } from './ConfirmBookingPayloadModel'
import {
  confirmBookingPayloadModelPrimitives,
  ConfirmBookingPayloadModelSelector,
} from './ConfirmBookingPayloadModel.base'
import { CreateBookingPayloadModel } from './CreateBookingPayloadModel'
import {
  createBookingPayloadModelPrimitives,
  CreateBookingPayloadModelSelector,
} from './CreateBookingPayloadModel.base'
import { ParkModel } from './ParkModel'
import { parkModelPrimitives, ParkModelSelector } from './ParkModel.base'
import { SaveCheckoutPayloadModel } from './SaveCheckoutPayloadModel'
import {
  saveCheckoutPayloadModelPrimitives,
  SaveCheckoutPayloadModelSelector,
} from './SaveCheckoutPayloadModel.base'
import { SavePickSitePayloadModel } from './SavePickSitePayloadModel'
import {
  savePickSitePayloadModelPrimitives,
  SavePickSitePayloadModelSelector,
} from './SavePickSitePayloadModel.base'
import { SaveTripDetailsPayloadModel } from './SaveTripDetailsPayloadModel'
import {
  saveTripDetailsPayloadModelPrimitives,
  SaveTripDetailsPayloadModelSelector,
} from './SaveTripDetailsPayloadModel.base'
import { SiteTypeModel } from './SiteTypeModel'
import {
  siteTypeModelPrimitives,
  SiteTypeModelSelector,
} from './SiteTypeModel.base'
import { UnitTypeModel } from './UnitTypeModel'
import {
  unitTypeModelPrimitives,
  UnitTypeModelSelector,
} from './UnitTypeModel.base'

/**
 * Store, managing, among others, all the objects received through graphQL
 */
export const RootStoreBase = MSTGQLStore.named('RootStore')
  .extend(
    configureStoreMixin(
      [
        ['Availability', () => AvailabilityModel],
        ['Booking', () => BookingModel],
        ['BookingOption', () => BookingOptionModel],
        ['ConfirmBookingPayload', () => ConfirmBookingPayloadModel],
        ['CreateBookingPayload', () => CreateBookingPayloadModel],
        ['Park', () => ParkModel],
        ['SaveCheckoutPayload', () => SaveCheckoutPayloadModel],
        ['SavePickSitePayload', () => SavePickSitePayloadModel],
        ['SaveTripDetailsPayload', () => SaveTripDetailsPayloadModel],
        ['SiteType', () => SiteTypeModel],
        ['UnitType', () => UnitTypeModel],
      ],
      [
        'Availability',
        'Booking',
        'BookingOption',
        'Park',
        'SiteType',
        'UnitType',
      ],
      'js'
    )
  )
  .props({
    availabilities: types.optional(
      types.map(types.late(() => AvailabilityModel)),
      {}
    ),
    bookings: types.optional(types.map(types.late(() => BookingModel)), {}),
    bookingOptions: types.optional(
      types.map(types.late(() => BookingOptionModel)),
      {}
    ),
    parks: types.optional(types.map(types.late(() => ParkModel)), {}),
    siteTypes: types.optional(types.map(types.late(() => SiteTypeModel)), {}),
    unitTypes: types.optional(types.map(types.late(() => UnitTypeModel)), {}),
  })
  .actions((self) => ({
    // Find a booking by ID
    queryBooking(
      variables,
      resultSelector = bookingModelPrimitives.toString(),
      options = {}
    ) {
      return self.query(
        `query booking($id: ID!) { booking(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new BookingModelSelector()).toString()
            : resultSelector
        }
      } }`,
        variables,
        options
      )
    },
    // Find a park by ID
    queryPark(
      variables,
      resultSelector = parkModelPrimitives.toString(),
      options = {}
    ) {
      return self.query(
        `query park($id: ID!) { park(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new ParkModelSelector()).toString()
            : resultSelector
        }
      } }`,
        variables,
        options
      )
    },
    mutateConfirmBooking(
      variables,
      resultSelector = confirmBookingPayloadModelPrimitives.toString(),
      optimisticUpdate
    ) {
      return self.mutate(
        `mutation confirmBooking($input: ConfirmBookingInput!) { confirmBooking(input: $input) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(
                new ConfirmBookingPayloadModelSelector()
              ).toString()
            : resultSelector
        }
      } }`,
        variables,
        optimisticUpdate
      )
    },
    mutateCreateBooking(
      variables,
      resultSelector = createBookingPayloadModelPrimitives.toString(),
      optimisticUpdate
    ) {
      return self.mutate(
        `mutation createBooking($input: CreateBookingInput!) { createBooking(input: $input) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new CreateBookingPayloadModelSelector()).toString()
            : resultSelector
        }
      } }`,
        variables,
        optimisticUpdate
      )
    },
    mutateSaveCheckout(
      variables,
      resultSelector = saveCheckoutPayloadModelPrimitives.toString(),
      optimisticUpdate
    ) {
      return self.mutate(
        `mutation saveCheckout($input: SaveCheckoutInput!) { saveCheckout(input: $input) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new SaveCheckoutPayloadModelSelector()).toString()
            : resultSelector
        }
      } }`,
        variables,
        optimisticUpdate
      )
    },
    mutateSavePickSite(
      variables,
      resultSelector = savePickSitePayloadModelPrimitives.toString(),
      optimisticUpdate
    ) {
      return self.mutate(
        `mutation savePickSite($input: SavePickSiteInput!) { savePickSite(input: $input) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new SavePickSitePayloadModelSelector()).toString()
            : resultSelector
        }
      } }`,
        variables,
        optimisticUpdate
      )
    },
    mutateSaveTripDetails(
      variables,
      resultSelector = saveTripDetailsPayloadModelPrimitives.toString(),
      optimisticUpdate
    ) {
      return self.mutate(
        `mutation saveTripDetails($input: SaveTripDetailsInput!) { saveTripDetails(input: $input) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(
                new SaveTripDetailsPayloadModelSelector()
              ).toString()
            : resultSelector
        }
      } }`,
        variables,
        optimisticUpdate
      )
    },
  }))
