import React from 'react'
import { observer } from 'mobx-react'
import { useQuery } from 'models/reactUtils'

import { Box, Flex } from '@chakra-ui/core'
import Step from './Step'

const Stepper = observer(() => {
  const { store } = useQuery()

  return (
    <>
      {store.view.steps && (
        <Box mb={10}>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-evenly"
          >
            {store.view.steps.map((step, index) => (
              <Step key={index} step={step} />
            ))}
          </Flex>
        </Box>
      )}
    </>
  )
})

export default Stepper
