import React from 'react'
import InputWrapper from './InputWrapper'
import { Textarea } from '@chakra-ui/core'

const InputTextarea = React.forwardRef(
  (
    {
      id,
      name,
      label,
      error,
      onChange,
      helperText,
      ariaID,
      isRequired,
      ...rest
    },
    ref
  ) => (
    <InputWrapper
      id={id}
      name={name}
      label={label}
      error={error}
      helperText={helperText}
      ariaID={ariaID}
      isRequired={isRequired}
    >
      <Textarea
        ref={ref}
        id={name}
        name={name}
        onChange={onChange}
        fontSize="base"
        height="textarea"
        borderColor="gray.300"
        borderRadius={6}
        focusBorderColor="primary"
        errorBorderColor="error"
        aria-label={label}
        aria-describedby={ariaID}
        isRequired={isRequired}
        isInvalid={error ? true : false}
        {...rest}
      />
    </InputWrapper>
  )
)

export default InputTextarea
