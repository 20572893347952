import React from 'react'
import { Button } from '@chakra-ui/core'

const SecondaryButton = ({ label, ...rest }) => {
  return (
    <>
      <Button
        height="input"
        px={6}
        py={3}
        fontSize="button"
        color="primary"
        letterSpacing="button"
        textTransform="uppercase"
        backgroundColor="transparent"
        borderWidth={1}
        borderStyle="solid"
        borderColor="primary"
        borderRadius={25}
        {...rest}
      >
        {label}
      </Button>
    </>
  )
}

export default SecondaryButton
