import { Parser } from 'html-to-react'

const Html = ({ content }) => {
  const parser = new Parser()
  const reactElement = parser.parse(content)

  return reactElement ? reactElement : null
}

export default Html
