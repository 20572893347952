import React from 'react'
import InputText from 'components/Inputs/InputText'

const MobileArriveDate = React.forwardRef(
  ({ minDate, maxDate, ...rest }, ref) => (
    <InputText
      ref={ref}
      name="arrivalDate"
      label="Arrive"
      type="date"
      mr="-2px"
      borderTopRightRadius={0}
      borderBottomRightRadius={0}
      _hover={{
        zIndex: 'inputFocused',
        borderColor: 'primary',
      }}
      _focus={{
        zIndex: 'inputFocused',
        borderColor: 'primary',
        boxShadow: '0 0 0 1px #b7760a',
      }}
      min={minDate?.format('yyyy-MM-DD')}
      max={maxDate?.format('yyyy-MM-DD')}
      pattern="\d{4}-\d{2}-\d{2}"
      {...rest}
    />
  )
)

export default MobileArriveDate
