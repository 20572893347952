import { types, getParent, getEnv } from 'mobx-state-tree'
import {
  StepModel,
  TripDetailsStepModel,
  PickSiteStepModel,
  CheckoutStepModel,
  ConfirmationStepModel,
} from 'store/models'
import { toast } from 'react-toastify'
import { getQueryStringParams } from 'utils/url'

export const ViewStore = types
  .model({
    stepMap: types.map(
      types.union(
        StepModel,
        TripDetailsStepModel,
        PickSiteStepModel,
        CheckoutStepModel,
        ConfirmationStepModel
      )
    ),
    // displayed: types.array(types.number),
    // notifications: types.array(Notification),
  })
  .views((self) => ({
    get store() {
      return getParent(self)
    },
    get routing() {
      return getEnv(self).routing
    },
    get queryParams() {
      return getQueryStringParams(self.routing.location.search)
    },
    get booking() {
      return self.store.booking
    },
    get steps() {
      return [...self.stepMap.values()]
    },
    get stepNames() {
      return [...self.stepMap.keys()]
    },
    get stepSlugs() {
      return self.steps.map((step) => step.slug)
    },
    get stepLabels() {
      return self.steps.map((step) => step.label)
    },
    step(id) {
      return id && self.stepMap.get(id)
    },
    stepIndex(id) {
      return self.steps.findIndex((rt) => id === rt.id)
    },
    get currentStep() {
      return self.steps[self.currentStepIndex]
    },
    get currentStepIndex() {
      const idx = self.steps.findIndex(
        (rt) => self.routing.location.pathname === rt.slug
      )
      return idx === -1 ? 0 : idx
    },
    isCurrentStep(id) {
      return self.currentStep && self.currentStep.id === id
    },
    isStep(id) {
      return id && self.currentStep.id === id
    },
    isPath(path) {
      return self.routing.location.pathname === path
    },
    get isFirstStep() {
      return self.currentStepIndex === 0
    },
    get isLastStep() {
      return self.currentStepIndex === self.steps.length - 1
    },
    get canGoBack() {
      return self.currentStepIndex > 0 && self.currentStepIndex <= 2
    },
  }))
  .actions((self) => ({
    goTo(path) {
      self.routing.push(path)
    },
    goToStep(id) {
      const slug = self.step(id)?.slug
      slug && self.goTo(slug)
    },
    goBack() {
      if (self.isStep('tripDetails')) {
        // Fix me
      }
      if (self.isStep('pickSite')) {
        self.goToStep('tripDetails')
      }
      if (self.isStep('checkout')) {
        self.goToStep('pickSite')
      }
    },
    goToLatest() {
      if (
        self.step('confirmation').isCompleted ||
        self.step('checkout').isCompleted
      ) {
        return self.goToStep('confirmation')
      }
      if (self.step('pickSite').isCompleted) {
        return self.goToStep('checkout')
      }
      if (self.step('tripDetails').isCompleted) {
        return self.goToStep('pickSite')
      }
    },
    afterCreate() {
      self.stepMap.put(
        TripDetailsStepModel.create({
          id: 'tripDetails',
          slug: '/trip-details',
          label: 'Trip Details',
        })
      )
      self.stepMap.put(
        PickSiteStepModel.create({
          id: 'pickSite',
          slug: '/pick-site',
          label: 'Pick Site',
        })
      )
      self.stepMap.put(
        CheckoutStepModel.create({
          id: 'checkout',
          slug: '/checkout',
          label: 'Checkout',
        })
      )

      self.stepMap.put(
        CheckoutStepModel.create({
          id: 'confirmation',
          slug: '/confirmation',
          label: 'Confirmation',
        })
      )

      toast.configure()
    },
    notify(message) {
      toast.info(message, {
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: 'loading',
        pauseOnFocusLoss: false,
      })
    },

    notifyError(message) {
      if (!toast.isActive('error')) {
        toast.error(message, {
          toastId: 'error',
          position: toast.POSITION.BOTTOM_CENTER,
          pauseOnFocusLoss: false,
        })
      }
    },
    dismiss(id) {
      toast.dismiss(id.current)
    },

    dismissAll() {
      toast.dismiss()
    },
    handleError(errors) {
      if (errors) {
        const errorString = Array.isArray(errors) ? errors.join(', ') : errors
        self.dismiss('loading')
        self.notifyError(errorString)
      }
      return undefined
    },
  }))

export default ViewStore
