import React from 'react'
import InputCheckboxGroup from 'components/Inputs/InputCheckboxGroup'

const BookingOptionCheckboxGroup = ({ booking, onChange, ...rest }) => {
  return (
    <>
      {booking.currentBooleanBookingOptions &&
        booking.currentBooleanBookingOptions.size > 0 && (
          <InputCheckboxGroup
            id="booleanBookingOptions"
            name="booleanBookingOptions"
            data={booking.currentAvailability.bookingOptions.filter(
              (bo) => bo.fieldType === '1'
            )}
            {...rest}
          />
        )}
    </>
  )
}

export default BookingOptionCheckboxGroup
