import React from 'react'
import { observer } from 'mobx-react'
import { ListItem, Flex, Text } from '@chakra-ui/core'

const BookingCostListItem = observer(({ label, value, ...rest }) => {
  return (
    <>
      <ListItem mb={4} fontSize="costItem">
        <Flex flexDirection="row" justifyContent="space-between">
          <Text as="span" maxW="costItem" fontWeight="400" {...rest}>
            {label}
          </Text>
          <Text as="span" fontWeight="600" color="primary">
            {value}
          </Text>
        </Flex>
      </ListItem>
    </>
  )
})

export default BookingCostListItem
