import React, { useRef } from 'react'
import {
  Box,
  Text,
  Button,
  Drawer,
  DrawerHeader,
  DrawerOverlay,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/core'
import Html from 'components/Layout/Html'

const ParkTerms = ({ park }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const drawerRef = useRef()

  return (
    <>
      <Box w="full" mt={4} textAlign="center">
        <Text mb={2} fontSize="largeBody" fontWeight="600">
          By clicking the button you agree with the site
        </Text>
        <Button
          ref={drawerRef}
          onClick={onOpen}
          variant="link"
          mb={10}
          fontWeight="400"
          color="primary"
          textDecoration="underline"
        >
          Terms & Conditions
        </Button>
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={drawerRef}
          size="sm"
        >
          <DrawerOverlay />
          <DrawerContent color="white" backgroundColor="gray.400">
            <DrawerCloseButton />
            <DrawerHeader fontSize="h4" fontWeight={400}>
              Terms & Conditions
            </DrawerHeader>
            <DrawerBody overflow="scroll">
              <Html content={park.terms} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </>
  )
}

export default ParkTerms
