import JSEncrypt from 'jsencrypt'
// import NodeRSA from 'node-rsa'
import creditCardType, { types as CardType } from 'credit-card-type'

export const formatCurrency = (cents, currency = 'USD', locale = 'us-EN') => {
  const amount = (cents || 0) / 100
  return _currencyFormatter(locale, currency).format(amount)
}

const _currencyFormatter = (locale = 'us-EN', currency = 'USD') => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  })
}

export const formatDollars = (cents, locale = 'us-EN') => {
  const amount = (cents || 0) / 100
  return _currencyFormatter(locale)
    .formatToParts(amount)
    .map(({ type, value }) => {
      switch (type) {
        case 'currency':
          return ''
        default:
          return value
      }
    })
    .reduce((string, part) => string + part)
}

export const rsaEncrypt = (publicKey, val) => {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey)
  return encryptor.encrypt(val)
}

export const getCCType = (val) => {
  return creditCardType(val).map((card) => {
    switch (card.type) {
      case CardType.VISA:
        return 'visa'
      case CardType.MASTERCARD:
        return 'mc'
      case CardType.DISCOVER:
        return 'discover'
      case CardType.AMERICAN_EXPRESS:
        return 'amex'
      default:
        return ''
    }
  })[0]
}

// export const rsaEncrypt = (publicKey, val) => {
//  const key = new NodeRSA(publicKey, 'der')
//  return key.encrypt(val, 'base64')
// }
