import React from 'react'
import { ListItem, Flex, Text } from '@chakra-ui/core'

const SummaryListItem = ({ label, value }) => (
  <ListItem mb={4} fontSize="base">
    <Flex flexDirection="row" justifyContent="space-between">
      <Text as="span" fontWeight="600">
        {label}:
      </Text>
      <Text as="span">{value}</Text>
    </Flex>
  </ListItem>
)

export default SummaryListItem
