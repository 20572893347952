import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import { useQuery } from 'models/reactUtils'

import { ToastContainer } from 'react-toastify'

import Routing from 'components/Routing/Routing'
import Title from 'components/Layout/Title'
import GlobalError from 'components/Error/GlobalError'

const App = observer(() => {
  const { store, data, loading, error } = useQuery((store) => {
    console.log(store.view.queryParams.park_id)
    store.setParkId(store.view.queryParams.park_id)
    return store.createBooking()
  })

  if (error) return <GlobalError error={error} />
  if (loading) return null

  const title = `${store.park.name} Online Reservations`

  return (
    <Fragment>
      <Title title={title} />
      <Routing
        store={store}
        booking={data.createBooking.booking}
        errors={data.createBooking.errors}
      />
      <ToastContainer limit={3} newestOnTop={true} />
    </Fragment>
  )
})

export default App
