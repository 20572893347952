import { types } from 'mobx-state-tree'
import moment from 'moment'

export const momentDate = types.custom({
  name: 'momentDate',
  fromSnapshot(value) {
    return moment(value)
  },
  toSnapshot(value) {
    return value.toISOString()
  },
  // tslint:disable-next-line:no-any
  isTargetType(v) {
    return moment.isMoment(v)
  },
  // tslint:disable-next-line:no-any
  getValidationMessage(v) {
    if (moment.isMoment(v)) {
      return 'Invalid moment object'
    }
    return ''
  },
})
