import React, { useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import {
  Button,
  Drawer,
  DrawerHeader,
  DrawerOverlay,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/core'
import Html from 'components/Layout/Html'

const ParkRules = ({ park }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' })
  const drawerRef = useRef()

  return (
    <>
      <Button
        ref={drawerRef}
        onClick={onOpen}
        variant="link"
        mb={10}
        fontWeight="400"
        color={['white', 'white', 'white', 'primary']}
        textDecoration="underline"
      >
        Park Info / Rules
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={drawerRef}
        size={isDesktop ? 'sm' : 'xs'}
      >
        <DrawerOverlay />
        <DrawerContent pb={10} color="white" backgroundColor="gray.400">
          <DrawerCloseButton />
          <DrawerHeader fontSize="h4" fontWeight={400}>
            Park Rules
          </DrawerHeader>
          <DrawerBody overflow="scroll">
            <Html content={park.notes} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default ParkRules
