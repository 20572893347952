import React from 'react'
import InputTextArea from 'components/Inputs/InputTextArea'

const Comments = React.forwardRef((props, ref) => (
  <InputTextArea
    ref={ref}
    name="notes"
    label="Comments (optional)"
    helperText="300 character maximum."
    ariaID="comments-helper-text"
    {...props}
  />
))

export default Comments
