import React from 'react'
import { observer } from 'mobx-react'
import PrimaryButton from 'components/Buttons/PrimaryButton'

const NextButton = observer(({ label, ...rest }) => (
  <PrimaryButton
    label={label}
    w={['full', 'full', 'full', 'auto']}
    mt={[4, 0]}
    ml={['', '', '', 4]}
    {...rest}
  />
))

export default NextButton
