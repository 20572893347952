import React from 'react'
import { observer } from 'mobx-react'
import { Box } from '@chakra-ui/core'
import { useForm, FormProvider } from 'react-hook-form'

import SitePicker from './Inputs/SitePicker'
import ButtonsWrapper from 'components/Buttons/ButtonsWrapper'
import BackButton from 'components/Buttons/BackButton'
import NextStepButton from 'components/Buttons/NextStepButton'

const PickSite = observer(({ store, booking, errors: bookingErrors }) => {
  const methods = useForm({
    mode: 'all',
    defaultValues: booking.pickSite,
  })
  const { handleSubmit, formState } = methods
  booking.store.view.step('pickSite').setFormState(formState)

  const onChange = ({ target: { name, value } }) => {
    booking.setValue(name, value)
    booking.store.view.step('pickSite').setFormState(formState)
  }

  const handleLoading = () => {
    store.view.dismissAll()
    store.view.notify('Creating a quote...')
  }

  const handleData = (data) => {
    store.view.dismiss('loading')
    if (data.savePickSite.errors.length > 0) {
      store.view.notifyError([...new Set(data.savePickSite.errors)].join(', '))
    } else if (bookingErrors.length > 0) {
      store.view.notifyError([...new Set(bookingErrors)].join(', '))
    } else if (bookingErrors.length === 0) {
      store.view.dismissAll()
      store.view.goToStep('checkout')
    }
  }

  return (
    <FormProvider {...methods}>
      <Box as="section" id="pick-site">
        <form>
          <SitePicker
            name="site"
            label="Pick Your Site"
            onChange={onChange}
            booking={booking}
            isRequired={true}
          >
            <NextStepButton
              label="Pick Your Site"
              handleSubmit={handleSubmit}
              disabled={!store.view.step('checkout').isAvailable}
              getQuery={store.savePickSite}
              onLoading={handleLoading}
              onData={handleData}
              onError={store.view.handleError}
            />
          </SitePicker>
          <ButtonsWrapper>
            <BackButton goToStep="tripDetails" />
            <NextStepButton
              label="Pick Your Site"
              handleSubmit={handleSubmit}
              disabled={!store.view.step('checkout').isAvailable}
              getQuery={store.savePickSite}
              onLoading={handleLoading}
              onData={handleData}
              onError={store.view.handleError}
            />
          </ButtonsWrapper>
        </form>
      </Box>
    </FormProvider>
  )
})

export default PickSite
