import React from 'react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/core'

const InputWrapper = ({
  id,
  name,
  label,
  helperText,
  ariaID,
  error,
  isRequired,
  children,
}) => (
  <FormControl id={id} mb={6} isInvalid={error} isRequired={isRequired}>
    <FormLabel
      display="block"
      htmlFor={name}
      fontSize="label"
      fontWeight="600"
      color="black"
    >
      {label}
    </FormLabel>
    {children}
    <FormErrorMessage mt={2}>{error}</FormErrorMessage>
    {helperText && (
      <FormHelperText id={ariaID} fontSize="helperText" color="gray.300">
        {helperText}
      </FormHelperText>
    )}
  </FormControl>
)

export default InputWrapper
