import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  firstName: yup.string().label('First Name').required(),
  lastName: yup.string().label('Last Name').required(),
  address: yup.string().label('Address').required(),
  city: yup.string().label('City').required(),
  country: yup.string().label('Country').required(),
  state: yup.string().label('State / Province').required(),
  postalCode: yup.string().label('Zip / Postal Code').required(),
  email: yup.string().email().label('Email').required(),
  confirmEmail: yup
    .string()
    .email()
    .required()
    .label('Confirm Email')
    .test('emails-match', 'Email and Confirm Email must match', function (
      value
    ) {
      return this.parent.email === value
    }),
  phone: yup.string().label('Phone').required(),
})
