import React from 'react'
import InputText from 'components/Inputs/InputText'

const MobileDepartDate = React.forwardRef(
  ({ minDate, maxDate, ...rest }, ref) => (
    <InputText
      ref={ref}
      name="departureDate"
      label="Depart"
      type="date"
      zIndex="base"
      ml="-2px"
      borderTopLeftRadius={0}
      borderBottomLeftRadius={0}
      _hover={{
        zIndex: 'inputFocused',
        borderColor: 'primary',
      }}
      _focus={{
        zIndex: 'inputFocused',
        borderColor: 'primary',
        boxShadow: '0 0 0 1px #b7760a',
      }}
      min={minDate?.format('yyyy-MM-DD')}
      max={maxDate?.format('yyyy-MM-DD')}
      pattern="\d{4}-\d{2}-\d{2}"
      {...rest}
    />
  )
)

export default MobileDepartDate
