import React from 'react'
import { Box, Flex } from '@chakra-ui/core'

const ButtonsWrapper = ({ children, ...rest }) => (
  <Box mt={10}>
    <Flex
      flexDirection={['column', 'row']}
      justifyContent={['', 'flex-end']}
      {...rest}
    >
      {children}
    </Flex>
  </Box>
)

export default ButtonsWrapper
