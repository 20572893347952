import React from 'react'
import InputText from 'components/Inputs/InputText'

const AddressSecondary = React.forwardRef((props, ref) => (
  <InputText
    ref={ref}
    name="address2"
    label="Apt, suite, etc (optional)"
    {...props}
  />
))

export default AddressSecondary
