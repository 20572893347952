import React from 'react'
import InputWrapper from 'components/Inputs/InputWrapper'
import { CheckboxGroup, Checkbox } from '@chakra-ui/core'
import { Controller } from 'react-hook-form'

const InputCheckboxGroup = ({
  id,
  name,
  label,
  error,
  control,
  data,
  onChange: onCheckboxChange,
  valueProperty = 'id',
  labelProperty = 'description',
}) => {
  const handleChange = (onChange) => (values) => {
    onChange(values)
    onCheckboxChange(values)
  }

  return (
    <InputWrapper id={id} name={name} label={label} error={error}>
      <Controller
        id={name}
        name={name}
        control={control}
        render={({ onChange, value }) => (
          <CheckboxGroup value={value} onChange={handleChange(onChange)}>
            {data.map((option) => (
              <Checkbox value={option[valueProperty]}>
                {option[labelProperty]}
              </Checkbox>
            ))}
          </CheckboxGroup>
        )}
      />
    </InputWrapper>
  )
}

export default InputCheckboxGroup
