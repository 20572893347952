import React, { Fragment, useState } from 'react'
import { observer } from 'mobx-react'
import InputWrapper from 'components/Inputs/InputWrapper'
import {
  RadioGroup,
  Box,
  Flex,
  Heading,
  Text,
  Radio,
  Button,
  Tag,
  TagLabel,
} from '@chakra-ui/core'
import styled from '@emotion/styled'

import BookingOptions from 'components/PickSite/Inputs/BookingOptions'
import SiteImages from './SiteImages'
import { siteForAvailability, amenities } from './config'

const AmenitiesList = styled.ul`
  column-count: 2;
`

const SitePrice = ({ site }) => (
  <Tag mt={2} size="lg" fontWeight="semibold" variantColor="gray">
    <TagLabel>
      {site.priceLabel} {site.price} {site.priceHelp}
    </TagLabel>
  </Tag>
)

const SitePicker = observer(
  ({ name, label, error, booking, onChange, children }) => {
    const [toggleState, setToggleState] = useState({})

    const toggleMore = (key) => {
      setToggleState((prev) => ({
        ...prev,
        [key]: !prev[key],
      }))
    }

    if (booking.availabilities.size <= 0) return null

    return (
      <InputWrapper name={name} label={label} error={error}>
        <RadioGroup
          onChange={onChange}
          value={booking.availabilityId}
          cursor="pointer"
        >
          {booking.availabilities.map((availability, index) => {
            const isChecked = booking.availabilityId === availability.id
            const site = siteForAvailability(availability)

            const margin = site.images.length > 1 ? '-16px' : 0
            site.key = `site-${index}`

            return (
              <Box
                key={site.key}
                position="relative"
                mb={6}
                px={4}
                py={8}
                borderWidth={4}
                borderStyle="solid"
                borderColor={isChecked ? 'primary' : 'gray.300'}
                borderRadius={6}
                textAlign="center"
              >
                <Flex flexDirection="column" alignItems="center">
                  <Heading
                    as="h2"
                    fontSize="h2"
                    fontWeight="600"
                    color="primary"
                    textTransform="uppercase"
                  >
                    {availability.description}
                  </Heading>
                  <SiteImages site={site} margin={margin} />
                  <Box my={6}>
                    <Text fontSize="lg">{availability.descriptionLong}</Text>
                    {site.address &&
                      site.city &&
                      site.state &&
                      site.zipcode &&
                      site.phone && (
                        <Heading as="h2" mt={6} fontSize="h2" color="primary">
                          {site.address},<br />
                          {site.city} {site.state} {site.zipcode}
                          <br />
                          {site.phone}
                        </Heading>
                      )}
                    {false && toggleState[site.key] && (
                      <Box w="full" mt={6} px={4}>
                        <AmenitiesList>
                          {amenities.slice(5, -1).map((amenity, index) => {
                            return (
                              <Box
                                key={index}
                                mb={4}
                                fontSize="base"
                                fontWeight="400"
                                textAlign="left"
                              >
                                {amenity}
                              </Box>
                            )
                          })}
                        </AmenitiesList>
                      </Box>
                    )}
                  </Box>
                  {false && amenities && (
                    <Box w="full" mt={6} px={4}>
                      <AmenitiesList>
                        {amenities.slice(0, 5).map((amenity, index) => {
                          return (
                            <Box
                              key={index}
                              mb={4}
                              fontSize="base"
                              fontWeight="400"
                              textAlign="left"
                            >
                              {amenity}
                            </Box>
                          )
                        })}
                      </AmenitiesList>
                    </Box>
                  )}
                  {false && (site.excerpt || amenities.length > 5) && (
                    <Button
                      variant="link"
                      position="relative"
                      zIndex="sitePickerMore"
                      mt={6}
                      fontWeight="400"
                      color="primary"
                      textDecoration="underline"
                      onClick={(e) => toggleMore(site.key)}
                    >
                      Show {toggleState[site.key] ? 'Less' : 'More'}
                    </Button>
                  )}
                  <Radio
                    key={index}
                    name="availabilityId"
                    value={availability.id}
                    position="absolute"
                    top={0}
                    bottom={0}
                    left={0}
                    right={0}
                    zIndex="sitePickerRadio"
                    appearance="none"
                    isChecked={isChecked}
                    onChange={() => {}}
                    onMouseEnter={() => {
                      booking.setValue('availabilityId', availability.id)
                    }}
                    readOnly
                  />
                </Flex>
                <SitePrice site={site} />
                {isChecked && (
                  <Fragment>
                    <BookingOptions
                      booking={booking}
                      availability={availability}
                      bookingOptions={booking.currentBookingOptions}
                      isRequired={true}
                      zIndex="1000"
                    />
                    {React.cloneElement(children, {
                      label: 'Reserve',
                      w: 'full',
                      maxW: 311,
                      mt: 6,
                      mx: 'auto',
                      zIndex: '1000',
                    })}
                  </Fragment>
                )}
              </Box>
            )
          })}
        </RadioGroup>
      </InputWrapper>
    )
  }
)

export default SitePicker
