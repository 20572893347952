/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'

/**
 * SiteTypeBase
 * auto generated base class for the model SiteTypeModel.
 */
export const SiteTypeModelBase = ModelBase.named('SiteType')
  .props({
    __typename: types.optional(types.literal('SiteType'), 'SiteType'),
    active: types.union(types.undefined, types.boolean),
    createdAt: types.union(types.undefined, types.frozen()),
    id: types.identifier,
    internalUid: types.union(types.undefined, types.null, types.integer),
    maxPeople: types.union(types.undefined, types.integer),
    maxTime: types.union(types.undefined, types.null, types.integer),
    minTime: types.union(types.undefined, types.null, types.integer),
    name: types.union(types.undefined, types.null, types.string),
    parkId: types.union(types.undefined, types.null, types.frozen()),
    uid: types.union(types.undefined, types.null, types.string),
    updatedAt: types.union(types.undefined, types.frozen()),
  })
  .views((self) => ({
    get store() {
      return self.__getStore()
    },
  }))

export class SiteTypeModelSelector extends QueryBuilder {
  get active() {
    return this.__attr(`active`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get id() {
    return this.__attr(`id`)
  }
  get internalUid() {
    return this.__attr(`internalUid`)
  }
  get maxPeople() {
    return this.__attr(`maxPeople`)
  }
  get maxTime() {
    return this.__attr(`maxTime`)
  }
  get minTime() {
    return this.__attr(`minTime`)
  }
  get name() {
    return this.__attr(`name`)
  }
  get parkId() {
    return this.__attr(`parkId`)
  }
  get uid() {
    return this.__attr(`uid`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
}
export function selectFromSiteType() {
  return new SiteTypeModelSelector()
}

export const siteTypeModelPrimitives = selectFromSiteType().active.createdAt
  .internalUid.maxPeople.maxTime.minTime.name.parkId.uid.updatedAt
