import React from 'react'
import InputSelect from 'components/Inputs/InputSelect'
import { countryOptions } from 'utils/i18n'

const Country = React.forwardRef((props, ref) => (
  <InputSelect
    ref={ref}
    name="country"
    label="Country"
    data={countryOptions()}
    {...props}
  />
))

export default Country
