import React from 'react'
import styled from '@emotion/styled'
import { Spinner } from '@chakra-ui/core'

const OverlayContainer = styled.div`
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 40;
  background-color: rgba(0, 0, 0, 0.5);
`

const Overlay = () => {
  return (
    <OverlayContainer>
      <Spinner color="white" />
    </OverlayContainer>
  )
}

export default Overlay
