import moment from 'moment'
import * as Yup from 'yup'

const nightCount = (arrival, departure) => {
  if (arrival && departure) {
    const arrivalDate = moment(arrival)
    const departureDate = moment(departure)

    let nights = Math.round(
      moment.duration(departureDate.diff(arrivalDate)).as('days')
    )
    if (!nights) return 0
    nights = nights <= 0 ? 0 : nights
    return nights
  }
  return 0
}

const formatDate = (date, FORMAT = 'MM-DD-yyyy') => {
  try {
    return date && date.isValid() ? date.format(FORMAT) : ''
  } catch (e) {
    return ''
  }
}
export class MomentDateSchemaType extends Yup.date {
  constructor() {
    super()
    this.validFormats = []
  }
  _typeCheck(value) {
    return (
      super._typeCheck(value) ||
      (moment.isMoment(value) && value.isValid()) ||
      !value
    )
  }
}

export { nightCount, formatDate }
