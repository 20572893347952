import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import { List, Divider } from '@chakra-ui/core'
import BookingSummaryListItem from './BookingSummaryListItem'

const ArriveListItem = observer(({ booking }) => (
  <BookingSummaryListItem label="Arrive" value={booking.formattedArrivalDate} />
))

const DepartListItem = observer(({ booking }) => (
  <BookingSummaryListItem
    label="Depart"
    value={booking.formattedDepartureDate}
  />
))

const NumberOfNightsListItem = observer(({ booking }) => (
  <BookingSummaryListItem label="# of Nights" value={booking.numberOfNights} />
))

const UnitTypeListItem = observer(({ booking }) => (
  <BookingSummaryListItem label="Unit Type" value={booking.unitTypeName} />
))

const UnitLengthListItem = observer(({ booking }) => (
  <BookingSummaryListItem
    label="Unit Length"
    value={booking.unitLength && `${booking.unitLength} ft`}
  />
))

const SiteTypeListItem = observer(({ booking }) => (
  <>
    {booking.currentAvailability && (
      <BookingSummaryListItem
        label="Site Type"
        value={booking.availabilityDescription}
      />
    )}
  </>
))

const BookingOptionListItems = observer(({ booking }) => (
  <>
    {booking.currentAvailability &&
      booking.currentAvailability.bookingOptions.map((bo) => {
        // TODO: Generalize this as a blacklist in admin
        if (bo.description === 'BYS') return null
        const value =
          bo.fieldType === 0 ? bo.quantity : bo.quantity > 0 ? 'Yes' : 'No'
        return (
          <BookingSummaryListItem
            key={bo.id}
            label={bo.description}
            value={value}
          />
        )
      })}
  </>
))

const SiteCostListItem = observer(({ booking }) => (
  <BookingSummaryListItem label="Site Cost" value={booking.subtotal} />
))

// const FeesListItem = observer(({ booking }) => (
//  <BookingSummaryListItem label="Resort Fees" value={booking.transactionFee} />
// ))

const TaxListItem = observer(({ booking }) => (
  <BookingSummaryListItem label="Tax" value={booking.tax} />
))

const TotalListItem = observer(({ booking }) => (
  <BookingSummaryListItem label="TOTAL" value={booking.total} />
))

const SiteCostItems = observer(({ booking }) => (
  <Fragment>
    {booking.store.view.isStep('checkout') ||
      (booking.store.view.isStep('confirmation') && (
        <Fragment>
          <Divider my={4} backgroundColor="black" />
          <SiteCostListItem booking={booking} />
          <TaxListItem booking={booking} />
          <TotalListItem booking={booking} />
        </Fragment>
      ))}
  </Fragment>
))

const BookingSummaryList = observer(({ booking }) => {
  return (
    <List>
      <ArriveListItem booking={booking} />
      <DepartListItem booking={booking} />
      <NumberOfNightsListItem booking={booking} />
      <UnitTypeListItem booking={booking} />
      <UnitLengthListItem booking={booking} />
      <SiteTypeListItem booking={booking} />
      <BookingOptionListItems booking={booking} />
      <SiteCostItems booking={booking} />
    </List>
  )
})

export default BookingSummaryList
