import React from 'react'
import { observer } from 'mobx-react'
import { useFormContext } from 'react-hook-form'
import { Grid } from '@chakra-ui/core'
import InputSelect from 'components/Inputs/InputSelect'
import BookingOptionCheckboxGroup from './BookingOptionCheckboxGroup'
import { integerOptions } from 'utils/helpers'

const displayProps = (index, length) => {
  const odd = index % 2 === 0
  const paired = index + 1 < length

  return odd
    ? paired
      ? {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          zIndex: 1000,
        }
      : {}
    : {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: 0,
        zIndex: 1000,
      }
}

const BookingOptions = observer(
  ({ booking, availability, onChange, zIndex, ...rest }) => {
    const { register, errors } = useFormContext()
    const bookingOptions = availability.currentBookingOptions
    const templateColumns = bookingOptions.length === 1 ? '' : 'repeat(2,1fr)'

    const handleChange = ({ target: { name, value } }) => {
      const id = name?.substr(15, 36) // extract id
      bookingOptions.map((bo) => {
        return booking.setBookingOptionQuantity(id, value)
      })
    }

    return (
      <Grid gridTemplateColumns={templateColumns}>
        {bookingOptions.map((bookingOption, index) => {
          const name = `bookingOptions[${bookingOption.id}]`
          const error =
            errors.bookingOptions && errors.bookingOptions[bookingOption.id]

          return (
            <InputSelect
              key={bookingOption.id}
              ref={register({
                required: `${bookingOption.description} is required`,
              })}
              name={name}
              label={bookingOption.description}
              data={integerOptions(
                bookingOption.minValue || 1,
                bookingOption.maxValue || 25
              )}
              error={error && error.message}
              defaultValue={String(bookingOption.quantity)}
              onChange={handleChange}
              {...displayProps(index, bookingOptions.length)}
              zIndex={1000}
            />
          )
        })}
        <BookingOptionCheckboxGroup booking={booking} onChange={onChange} />
      </Grid>
    )
  }
)

export default BookingOptions
