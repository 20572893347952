import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import { render } from 'react-dom'
import { Router } from 'react-router'
import * as serviceWorker from './serviceWorker'
import { createBrowserHistory } from 'history'
import { syncHistoryWithStore, RouterStore } from 'mobx-react-router'
import { addMiddleware } from 'mobx-state-tree'

import { createHttpClient } from 'mst-gql'
import { StoreContext } from 'models'
import { simpleActionLogger } from 'mst-middlewares'

import TagManager from 'react-gtm-module'
import { hotjar } from 'react-hotjar'

import { ThemeProvider, CSSReset } from '@chakra-ui/core'
import { theme } from 'styles/theme'
import 'styles/overrides.css'
import 'typeface-roboto'
import 'typeface-roboto-condensed'

import { RootStore, ViewStore } from 'store'

import App from 'App'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
}

if (process.env.NODE_ENV === 'production') {
  hotjar.initialize(
    process.env.REACT_APP_HOTJAR_HJID,
    process.env.REACT_APP_HOTJAR_HJSV
  )
  TagManager.initialize(tagManagerArgs)
}

const routing = new RouterStore()
const view = ViewStore.create({})
const rootStore = RootStore.create(
  {
    view: view,
  },
  {
    gqlHttpClient: createHttpClient(process.env.REACT_APP_GRAPHQL_URI, {
      mode: 'cors',
    }),
    routing: routing,
  }
)
window.store = rootStore
addMiddleware(rootStore, simpleActionLogger)

const browserHistory = createBrowserHistory()
const history = syncHistoryWithStore(browserHistory, routing)

function renderApp() {
  render(
    <StoreContext.Provider value={rootStore}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <CSSReset />
          <App />
        </ThemeProvider>
      </Router>
    </StoreContext.Provider>,
    document.getElementById('root')
  )
}

renderApp()

if (module.hot) {
  module.hot.accept(['./App'], () => {
    renderApp()
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
