import { theme as defaultTheme } from '@chakra-ui/core'
import { icons } from './icons'
import 'react-toastify/dist/ReactToastify.min.css'

export const theme = {
  ...defaultTheme,
  fontSizes: {
    base: 18,
    h1: 32,
    h2: 24,
    h4: 22,
    label: 18,
    button: 20,
    costItem: 20,
    alert: 20,
    legal: 16,
    large: 28,
    largeBody: 20,
    helperText: 16,
  },
  fonts: {
    body: 'Montserrat, sans-serif',
    heading: 'Montserrat, sans-serif',
    headingCondensed: 'Roboto Condensed, sans-serif',
  },
  colors: {
    ...defaultTheme.colors,
    black: '#000a12',
    white: '#fff',
    primary: '#b7760a',
    secondary: '#4476a6',
    tertiary: '#42bd5b',
    gray: {
      100: '#f6f8f9',
      200: '#dde2e8',
      300: '#b8c1cb',
      400: '#778f9b',
    },
    focus: '#0253d7',
    error: '#ff4141',
    success: '#48bb78',
    warning: '#ecc94b',
    info: '#4299e1',
  },
  sizes: {
    ...defaultTheme.sizes,
    auto: 'auto',
    default: 1024,
    full: '100%',
    input: 50,
    textarea: 100,
    button: 24,
    costItem: 346,
    icon: 30,
    iconXSmall: 20,
    iconSmall: 24,
    stepCircle: 48,
    stepLineWidth: 164,
    stepLineHeight: 2,
  },
  space: {
    ...defaultTheme.sizes,
    edge: 15,
    aside: 24,
  },
  letterSpacings: {
    ...defaultTheme.letterSpacings,
    button: 0.2,
  },
  icons: {
    ...defaultTheme.icons,
    ...icons,
  },
  zIndices: {
    ...defaultTheme.zIndices,
    inputFocused: 2,
    sitePickerMore: 10,
    sitePickerRadio: 5,
  },
}
