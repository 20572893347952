import React from 'react'
import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'
import { Box } from '@chakra-ui/core'

import BookingHoldNotice from '../Booking/BookingHoldNotice'
import BookingCostList from 'components/Booking/BookingCostList'
import BookingReceipt from '../Booking/BookingReceipt'
import ButtonsWrapper from 'components/Buttons/ButtonsWrapper'
import BookNowButton from 'components/Buttons/BookNowButton'
import ParkTerms from 'components/Park/ParkTerms'
import BackButton from 'components/Buttons/BackButton'
import TagManager from 'react-gtm-module'

const Confirmation = observer(({ store, booking, errors: bookingErrors }) => {
  const { handleSubmit } = useForm({
    mode: 'all',
    defaultValues: booking.checkout,
  })

  const handleLoading = () => {
    store.view.dismissAll()
    store.view.notify('Booking your reservation...')
  }

  const handleData = (data) => {
    store.view.dismiss('loading')
    if (bookingErrors.length > 0) {
      store.view.notifyError([...new Set(bookingErrors)].join(', '))
    } else if (booking.isExpired) {
      store.view.notifyError('Your reservation hold has expired')
    } else {
      const tagManagerArgs = {
        dataLayer: {
          event: 'purchase',
          ecommerce: {
            purchase: {
              transaction_id: booking.bookingNumber,
              affiliation: store.park.name,
              value: booking.holdTotalDollars,
              tax: booking.taxDollars,
              shipping: '0.00',
              currency: booking.holdTotalCurrency, // TODO: Fix ME
              items: [
                {
                  item_id: booking.currentAvailability?.siteType?.internalUid,
                  item_name: booking.currentAvailability?.description,
                  item_price: booking.subtotalPerNightDollars,
                },
              ],
            },
          },
        },
        dataLayerName: 'ConfirmationDataLayer',
      }
      TagManager.dataLayer(tagManagerArgs)
    }
  }

  return (
    <Box as="section" id="confirmation">
      <BookingHoldNotice booking={booking} />
      {booking.isBooked ? (
        <BookingReceipt booking={booking} />
      ) : (
        <BookingCostList booking={booking} />
      )}
      <ButtonsWrapper flexWrap="wrap" justifyContent="center">
        <>
          {!booking.isBooked && !booking.isCancelled && !booking.isExpired ? (
            <BookNowButton
              booking={booking}
              handleSubmit={handleSubmit}
              handleData={handleData}
              disabled={!store.view.step('confirmation').isAvailable}
              getQuery={store.confirmBooking}
              onLoading={handleLoading}
              onData={handleData}
              onError={store.view.handleError}
            />
          ) : (
            <>
              {!booking.isBooked &&
                !booking.isCancelled &&
                booking.isExpired && (
                  <BackButton label="Go Back to Checkout" goToStep="checkout" />
                )}
              {booking.isBooked && (
                <BackButton
                  label="Book Another Site"
                  goToStep="tripDetails"
                  onClick={store.startOver}
                />
              )}
            </>
          )}
        </>
      </ButtonsWrapper>
    </Box>
  )
})

export default Confirmation
