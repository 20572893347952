import React from 'react'
import InputSelect from 'components/Inputs/InputSelect'

const UnitType = React.forwardRef(
  ({ unitTypes = [], error, onChange, ...rest }, ref) => (
    <InputSelect
      ref={ref}
      name="unitTypeId"
      label="Unit Type"
      labelProperty="name"
      valueProperty="id"
      data={unitTypes}
      error={error}
      onChange={onChange}
      {...rest}
    />
  )
)

export default UnitType
