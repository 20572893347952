import React from 'react'
import { observer } from 'mobx-react'
import { useQuery } from 'models/reactUtils'
import NextButton from 'components/Buttons/NextButton'

const NextStepButton = observer(
  ({
    disabled,
    handleSubmit,
    getQuery,
    label,
    onLoading,
    onData,
    onError,
    ...rest
  }) => {
    const { query, setQuery, store, loading } = useQuery()

    query &&
      query.case({
        loading: onLoading,
        data: onData,
        error: onError,
      })

    const handleClick = () => {
      handleSubmit((_data) => setQuery(getQuery(store)))()
    }

    return (
      <NextButton
        label={label}
        isDisabled={disabled || loading}
        isLoading={loading}
        loadingText="Saving..."
        onClick={handleClick}
        {...rest}
      />
    )
  }
)

export default NextStepButton
