/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
import { types } from 'mobx-state-tree'

/**
 * BookingAction
 */
export const BookingActionEnumType = types.enumeration('BookingAction', [
  'search', // Search for available sites
  'quote', // Generate a quote
  'hold', // Place a hold on a site
  'confirm', // Confirm the booking
  'cancel', // Cancel the booking
])
