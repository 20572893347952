/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { SiteTypeModel } from './SiteTypeModel'
import { SiteTypeModelSelector } from './SiteTypeModel.base'
import { UnitTypeModel } from './UnitTypeModel'
import { UnitTypeModelSelector } from './UnitTypeModel.base'

/**
 * ParkBase
 * auto generated base class for the model ParkModel.
 */
export const ParkModelBase = ModelBase.named('Park')
  .props({
    __typename: types.optional(types.literal('Park'), 'Park'),
    active: types.union(types.undefined, types.boolean),
    address: types.union(types.undefined, types.null, types.string),
    address2: types.union(types.undefined, types.null, types.string),
    baseUrl: types.union(types.undefined, types.null, types.string),
    city: types.union(types.undefined, types.null, types.string),
    clientId: types.union(types.undefined, types.null, types.frozen()),
    country: types.union(types.undefined, types.null, types.string),
    createdAt: types.union(types.undefined, types.frozen()),
    creditCards: types.union(types.undefined, types.frozen()),
    description: types.union(types.undefined, types.null, types.string),
    email: types.union(types.undefined, types.null, types.string),
    id: types.identifier,
    lat: types.union(types.undefined, types.null, types.number),
    leadDays: types.union(types.undefined, types.integer),
    lng: types.union(types.undefined, types.null, types.number),
    logoUrl: types.union(types.undefined, types.null, types.string),
    maxNights: types.union(types.undefined, types.integer),
    minNights: types.union(types.undefined, types.integer),
    name: types.union(types.undefined, types.null, types.string),
    nextBookingDate: types.union(types.undefined, types.null, types.frozen()),
    notes: types.union(types.undefined, types.null, types.string),
    phone: types.union(types.undefined, types.null, types.string),
    postalCode: types.union(types.undefined, types.null, types.string),
    reservationSystem: types.union(types.undefined, types.string),
    sandbox: types.union(types.undefined, types.boolean),
    securityKeyCiphertext: types.union(
      types.undefined,
      types.null,
      types.string
    ),
    siteTypes: types.union(
      types.undefined,
      types.null,
      types.array(MSTGQLRef(types.late(() => SiteTypeModel)))
    ),
    state: types.union(types.undefined, types.null, types.string),
    systemTerms: types.union(types.undefined, types.null, types.string),
    terms: types.union(types.undefined, types.null, types.string),
    unitTypes: types.union(
      types.undefined,
      types.null,
      types.array(MSTGQLRef(types.late(() => UnitTypeModel)))
    ),
    updatedAt: types.union(types.undefined, types.frozen()),
    usernameCiphertext: types.union(types.undefined, types.null, types.string),
  })
  .views((self) => ({
    get store() {
      return self.__getStore()
    },
  }))

export class ParkModelSelector extends QueryBuilder {
  get active() {
    return this.__attr(`active`)
  }
  get address() {
    return this.__attr(`address`)
  }
  get address2() {
    return this.__attr(`address2`)
  }
  get baseUrl() {
    return this.__attr(`baseUrl`)
  }
  get city() {
    return this.__attr(`city`)
  }
  get clientId() {
    return this.__attr(`clientId`)
  }
  get country() {
    return this.__attr(`country`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get creditCards() {
    return this.__attr(`creditCards`)
  }
  get description() {
    return this.__attr(`description`)
  }
  get email() {
    return this.__attr(`email`)
  }
  get id() {
    return this.__attr(`id`)
  }
  get lat() {
    return this.__attr(`lat`)
  }
  get leadDays() {
    return this.__attr(`leadDays`)
  }
  get lng() {
    return this.__attr(`lng`)
  }
  get logoUrl() {
    return this.__attr(`logoUrl`)
  }
  get maxNights() {
    return this.__attr(`maxNights`)
  }
  get minNights() {
    return this.__attr(`minNights`)
  }
  get name() {
    return this.__attr(`name`)
  }
  get nextBookingDate() {
    return this.__attr(`nextBookingDate`)
  }
  get notes() {
    return this.__attr(`notes`)
  }
  get phone() {
    return this.__attr(`phone`)
  }
  get postalCode() {
    return this.__attr(`postalCode`)
  }
  get reservationSystem() {
    return this.__attr(`reservationSystem`)
  }
  get sandbox() {
    return this.__attr(`sandbox`)
  }
  get securityKeyCiphertext() {
    return this.__attr(`securityKeyCiphertext`)
  }
  get state() {
    return this.__attr(`state`)
  }
  get systemTerms() {
    return this.__attr(`systemTerms`)
  }
  get terms() {
    return this.__attr(`terms`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get usernameCiphertext() {
    return this.__attr(`usernameCiphertext`)
  }
  siteTypes(builder) {
    return this.__child(`siteTypes`, SiteTypeModelSelector, builder)
  }
  unitTypes(builder) {
    return this.__child(`unitTypes`, UnitTypeModelSelector, builder)
  }
}
export function selectFromPark() {
  return new ParkModelSelector()
}

export const parkModelPrimitives = selectFromPark().active.address.address2
  .baseUrl.city.clientId.country.createdAt.creditCards.description.email.lat
  .leadDays.lng.logoUrl.maxNights.minNights.name.nextBookingDate.notes.phone
  .postalCode.reservationSystem.sandbox.securityKeyCiphertext.state.systemTerms
  .terms.updatedAt.usernameCiphertext
