import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Box, Heading, Grid } from '@chakra-ui/core'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './validationSchema'

import MobileArriveDate from './Inputs/MobileArriveDate'
import MobileDepartDate from './Inputs/MobileDepartDate'
import UnitType from './Inputs/UnitType'
import UnitLength from './Inputs/UnitLength'

// import Overlay from 'components/Loading/Overlay'
import BackButton from 'components/Buttons/BackButton'
import NextStepButton from 'components/Buttons/NextStepButton'
import ButtonsWrapper from 'components/Buttons/ButtonsWrapper'

const TripDetails = ({ store, booking, errors: bookingErrors }) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' })
  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: store.booking.tripDetails,
  })
  const { register, handleSubmit, errors, formState } = methods

  booking.store.view.step('tripDetails').setFormState(formState)

  const onChange = ({ target: { name, value } }) => {
    booking.setValue(name, value)
    booking.store.view.step('tripDetails').setFormState(formState)
  }

  const onMobileDateChange = ({ target: { name, value } }) => {
    booking.setValue(name, value)
    booking.store.view.step('tripDetails').setFormState(formState)
  }

  const handleLoading = () => {
    store.view.dismissAll()
    store.view.notify('Searching for available sites...')
  }

  const handleData = (data) => {
    console.log(data.saveTripDetails.errors.length)
    console.log(bookingErrors.length)

    store.view.dismiss('loading')
    if (data.saveTripDetails.errors.length > 0) {
      store.view.notifyError(
        [...new Set(data.saveTripDetails.errors)].join(', ')
      )
    } else if (bookingErrors.length > 0) {
      store.view.notifyError([...new Set(bookingErrors)].join(', '))
    } else if (booking.reasonCodes.length > 0) {
      store.view.notifyError([...new Set(booking.reasonCodes)].join(', '))
    } else if (booking.availabilities.length === 0) {
      store.view.notifyError(
        'There were no available sites matching your search'
      )
    } else {
      store.view.dismissAll()
      store.view.goToStep('pickSite')
    }
  }

  return (
    <Box as="section" id="trip-details">
      {isDesktop && (
        <Heading as="h1" mb={6} fontSize="h1" fontWeight="500">
          Complete Trip Details
        </Heading>
      )}
      <FormProvider {...methods}>
        <form>
          <Grid gridTemplateColumns="repeat(2,1fr)">
            <MobileArriveDate
              id="arriveDate"
              ref={register}
              onChange={onMobileDateChange}
              isRequired={true}
              error={errors.arrivalDate?.message}
              minDate={booking.minArrivalDate}
              maxDate={booking.maxArrivalDate}
            />
            <MobileDepartDate
              id="departDate"
              ref={register}
              onChange={onMobileDateChange}
              isRequired={true}
              error={errors.departureDate?.message}
              minDate={booking.minDepartureDate}
              maxDate={booking.maxDepartureDate}
            />
          </Grid>
          <UnitType
            ref={register}
            unitTypes={booking.park.unitTypes}
            onChange={onChange}
            isRequired={true}
            error={errors.unitTypeId?.message}
          />
          <UnitLength
            ref={register}
            unitTypeId={booking.unitTypeId}
            unitTypes={booking.park.unitTypes}
            onChange={onChange}
            isRequired={true}
            error={errors.unitLength?.message}
          />
          <ButtonsWrapper>
            {isDesktop && <BackButton label="Back To Home" disabled={true} />}
            <NextStepButton
              label="Pick Your Site"
              handleSubmit={handleSubmit}
              disabled={!formState.isValid}
              getQuery={store.saveTripDetails}
              onLoading={handleLoading}
              onData={handleData}
              onError={store.view.handleError}
            />
          </ButtonsWrapper>
        </form>
      </FormProvider>
    </Box>
  )
}

export default TripDetails
