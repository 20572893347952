/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { BookingModel } from './BookingModel'
import { BookingModelSelector } from './BookingModel.base'

/**
 * CreateBookingPayloadBase
 * auto generated base class for the model CreateBookingPayloadModel.
 *
 * Autogenerated return type of CreateBooking
 */
export const CreateBookingPayloadModelBase = ModelBase.named(
  'CreateBookingPayload'
)
  .props({
    __typename: types.optional(
      types.literal('CreateBookingPayload'),
      'CreateBookingPayload'
    ),
    booking: types.union(
      types.undefined,
      MSTGQLRef(types.late(() => BookingModel))
    ),
    /** A unique identifier for the client performing the mutation. */
    clientMutationId: types.union(types.undefined, types.null, types.string),
    errors: types.union(types.undefined, types.array(types.string)),
  })
  .views((self) => ({
    get store() {
      return self.__getStore()
    },
  }))

export class CreateBookingPayloadModelSelector extends QueryBuilder {
  get clientMutationId() {
    return this.__attr(`clientMutationId`)
  }
  get errors() {
    return this.__attr(`errors`)
  }
  booking(builder) {
    return this.__child(`booking`, BookingModelSelector, builder)
  }
}
export function selectFromCreateBookingPayload() {
  return new CreateBookingPayloadModelSelector()
}

export const createBookingPayloadModelPrimitives = selectFromCreateBookingPayload()
  .clientMutationId.errors
