import { countries } from 'coutries-states'

export const countryOptions = () => {
  return countries.map((country) => ({
    value: country.iso,
    label: country.name,
  }))
}

export const country = (iso) => {
  if (!iso) return
  return countries.find((c) => c.iso === iso)
}

export const stateOptions = (iso) => {
  if (!iso) return []

  return country(iso).states.map((state) => ({
    value: state.iso,
    label: state.name,
  }))
}
